import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import './CalculateurCotisations.css';
import React from 'react';
import BreadCrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import CalculateurCotisationsForm from '../../../components/forms/calculateurCotisationsForm/CalculateurCotisationsForm';

function CalculateurCotisations() {
  return (
    <>
      <section className='container page-container'>
        <BreadCrumbs
          routes={[
            {
              to: '/',
              name: 'Accueil',
            },
            {
              to: '/calculateur-cotisations',
              name: 'Calculateur de cotisations',
            },
          ]}
        />
        <Banner title='Calculateur de cotisations' subtitle='Estimez le montant des cotisations patronales' />
        <CalculateurCotisationsForm />
      </section>
    </>
  );
}

export default CalculateurCotisations;
