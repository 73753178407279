import Cross from '../../atoms/icons/general/cross/Cross';
import './Notification.css';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

function Notification({ createNotificationFunction }) {
  const [notifications, setNotifications] = useState([]);
  const [notificationsTotalHeight, setNotificationsTotalHeight] = useState(0);
  const [animatedContainer, setAnimatedContainer] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    createNotificationFunction.current = createNotification;
  }, []);

  useEffect(() => {
    calcNotificationsTotalHeight();
  }, [notifications]);

  function createNotification(content, bgColor, color, duration) {
    const id = uuidv4();
    const notification = {
      id,
      content,
      bgColor: bgColor,
      color,
      visible: true,
      appearing: true,
    };

    setTimeout(() => {
      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification.id === id ? { ...notification, appearing: false } : notification
        )
      );
    }, 300);

    setNotifications(prevNotifications => [notification, ...prevNotifications]);
    hideNotification(id, duration);
  }

  function hideNotification(id, delay = 0) {
    setTimeout(() => {
      setAnimatedContainer(false);
      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification.id === id ? { ...notification, visible: false } : notification
        )
      );
      setTimeout(() => {
        setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
        setTimeout(() => {
          setAnimatedContainer(true);
        }, 100);
      }, 250);
    }, delay);
  }

  function calcNotificationsTotalHeight() {
    if (!containerRef.current) return;

    let totalHeight = 0;
    containerRef.current.childNodes.forEach(notification => (totalHeight += notification.offsetHeight));
    totalHeight += 10 * (containerRef.current.childNodes.length - 1);

    setNotificationsTotalHeight(totalHeight);
  }

  return (
    <section
      className={`notifications-group-container ${animatedContainer ? 'animated-notifications-group-container' : ''}`}
      ref={containerRef}
      style={{ '--max-height': notificationsTotalHeight + 'px' }}>
      {notifications.map((notification, key) => {
        return (
          <div
            className={`notification-container notification-slide-up ${
              notification.visible ? (notification.appearing ? 'fade-in' : '') : 'slide-out'
            }`}
            style={{ '--background-color': notification.bgColor, '--color': notification.color }}
            key={key}>
            <div className='notification-content'>{notification.content}</div>
            <Cross color={notification.color} onClick={() => hideNotification(notification.id)} width='15px' />
          </div>
        );
      })}
    </section>
  );
}

export default Notification;
