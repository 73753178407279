import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { max2500Validation, numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import Info from '../../../../atoms/icons/general/info/Info.js';
import { ParcoursContext } from '../../../../../context/ParcoursContext.js';
import './Retraitements.css';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput.js';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import LightBulb from '../../../../atoms/icons/header/lightBulb/LightBulb.js';
import CalculateurCotisationsForm from '../../../calculateurCotisationsForm/CalculateurCotisationsForm.js';
import FilledButton from '../../../../molecules/buttons/filledButton/FilledButton.js';
import Cursor from '../../../../atoms/icons/parcours/cursor/Cursor.js';
import { formatNumberWithSpaces, removeSpaces } from '../../../../../utils/Utils.js';

function Retraitements({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const Form = { watch, setValue, register };
  const [ca, setCa] = useState(0);
  const [ebe, setEbe] = useState(0);
  const [calculateurVisible, setCalculateurVisible] = useState(false);
  const {
    showTooltip,
    estimationValues,
    moveToNextStep,
    parcoursTopRef,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    getStepToGo,
    setGoToStep,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const inputsWatcher = watch();
  const anchorRef = useRef(null);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'retraitements',
      [
        'salaire_gerant_annuel',
        'majoration_charges_externes',
        'charges_sociales_gerant_annuelles',
        'charges_ext_exp',
        'remontees_holding_dirigeant',
      ],
      setValue,
      estimationValues
    );
    if (!secondaryParcours) setValue('note_step_7', estimationValues?.notes?.note_step_7);
  }, [estimationValues]);

  useEffect(() => {
    scrollForCalculateur();
  }, [calculateurVisible]);

  useEffect(() => {
    calcRecap(inputsWatcher);
  }, [estimationValues, inputsWatcher]);

  async function formSubmit(values) {
    const tmp = {
      retraitements: {
        salaire_gerant_annuel: removeSpaces(values.salaire_gerant_annuel, 1),
        majoration_charges_externes: removeSpaces(values.majoration_charges_externes, 1),
        charges_sociales_gerant_annuelles: removeSpaces(values.charges_sociales_gerant_annuelles, 1),
        charges_ext_exp: removeSpaces(values.charges_ext_exp, 1),
        remontees_holding_dirigeant: removeSpaces(values.remontees_holding_dirigeant, 1),
        ebe: ebe,
      },
    };
    if (!secondaryParcours) tmp.notes = { note_step_7: values.note_step_7 };

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  function calcRecap(inputsWatcher) {
    if (!estimationValues) return;

    let result =
      (estimationValues?.ca?.ca_ht ?? 0) +
      (estimationValues?.ca?.ca_hebergement_ht ?? 0) +
      (estimationValues?.ca?.ca_restauration_divers_ht ?? 0) +
      (estimationValues?.ca?.total_commissions_nettes ?? 0) -
      (estimationValues?.charges?.achat_marchandise ?? 0) -
      (estimationValues?.charges?.total_charges_externes ?? 0) -
      removeSpaces(inputsWatcher?.majoration_charges_externes) -
      (estimationValues?.impots_taxes?.total_impot ?? 0) -
      (estimationValues?.remunerations?.salaires ?? 0) -
      (estimationValues?.remunerations?.charges_sociales ?? 0) -
      (estimationValues?.redevance?.redevance ?? 0) +
      (estimationValues?.impots_taxes?.csg_crds_exploitant ?? 0) +
      removeSpaces(inputsWatcher?.salaire_gerant_annuel) +
      removeSpaces(inputsWatcher?.charges_sociales_gerant_annuelles) +
      removeSpaces(inputsWatcher?.charges_ext_exp) +
      removeSpaces(inputsWatcher?.remontees_holding_dirigeant);

    setCa(
      estimationValues?.ca?.ca_ht +
        estimationValues?.ca?.ca_hebergement_ht +
        estimationValues?.ca?.ca_restauration_divers_ht
    );
    setEbe(result);
  }

  function scrollForCalculateur() {
    if (step != 7) return;
    if (calculateurVisible) parcoursTopRef?.current.scrollIntoView({ behavior: 'smooth' });
    else
      setTimeout(() => {
        anchorRef?.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
  }

  function setParcoursValues(salaire, charges) {
    setValue('salaire_gerant_annuel', salaire);
    setValue('charges_sociales_gerant_annuelles', charges);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <CalculateurCotisationsForm
        visible={calculateurVisible}
        setVisible={setCalculateurVisible}
        setParcoursValues={setParcoursValues}
        className={calculateurVisible ? 'mb-md' : ''}
      />
      <p className='warning-parcours'>Renseigner cette étape est primordial pour une bonne évaluation</p>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>retraitements</h2>
        <div className='row-1000 mb-md'>
          <FilledButton
            bgColor='var(--gold)'
            className='retraitement-button'
            onClick={() => showTooltip('Tuto_Bien_Retraiter.webp')}>
            Comment bien retraiter vos charges pour obtenir une meilleure estimation&nbsp;?
            <Cursor />
            <div className='shine-effect' />
          </FilledButton>
          <FilledButton
            bgColor='var(--dark-blue)'
            className='retraitement-button'
            onClick={() => setCalculateurVisible(!calculateurVisible)}>
            Calculez automatiquement vos charges sociales depuis le salaire net ou brut
            <Cursor />
            <div className='shine-effect' />
          </FilledButton>
        </div>
        <div className='row-1000' ref={anchorRef}>
          <NumberInput
            useForm={Form}
            label={
              <>
                rémunération annuelle du (des) dirigeant(s)
                <Info onClick={() => showTooltip('8_Retraitements_CASE_REM_DIRIGEANTS.webp')} />
              </>
            }
            tip='Et des salariés à ne pas conserver'
            name='salaire_gerant_annuel'
            error={errors?.salaire_gerant_annuel && errors.salaire_gerant_annuel.message}
            validation={numberValidation}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            label={
              <>
                charges sociales annuelles du (des) dirigeant(s)
                <Info onClick={() => showTooltip('8_Retraitements_CASE_CHG_SOC_DIRIGEANTS.webp')} />
              </>
            }
            tip='Et des salariés à ne pas conserver'
            name='charges_sociales_gerant_annuelles'
            error={errors?.charges_sociales_gerant_annuelles && errors.charges_sociales_gerant_annuelles.message}
            validation={numberValidation}
            icon='euro'
          />
        </div>
        <NumberInput
          useForm={Form}
          label={
            <>
              charges externes a retraiter & complements de remuneration du/des dirigeants
              <Info onClick={() => showTooltip('8_Retraitements_CASE_charges_externes.webp')} />
            </>
          }
          name='charges_ext_exp'
          error={errors?.charges_ext_exp && errors.charges_ext_exp.message}
          validation={numberValidation}
          icon='euro'
          bgColor='var(--pale-blue)'
        />
        <NumberInput
          useForm={Form}
          label={
            <>
              majoration des charges externes
              <Info onClick={() => showTooltip('TutoMajorationchargesparcoursFDCetTITRES.webp')} />
            </>
          }
          tip='Et des salariés à ne pas conserver'
          name='majoration_charges_externes'
          error={errors?.majoration_charges_externes && errors.majoration_charges_externes.message}
          validation={numberValidation}
          icon='euro'
        />
        <NumberInput
          useForm={Form}
          label={
            <>
              remontées holding dirigeant(s)
              <Info onClick={() => showTooltip('8_Retraitements_CASE_remontee_holding.webp')} />
            </>
          }
          tip='Et des salariés à ne pas conserver'
          name='remontees_holding_dirigeant'
          error={errors?.remontees_holding_dirigeant && errors.remontees_holding_dirigeant.message}
          validation={numberValidation}
          icon='euro'
          onFocus={() => {
            if (step == index + 1 && secondaryParcours) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && secondaryParcours) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        {!secondaryParcours && (
          <Textarea
            register={register}
            name='note_step_7'
            label='notes et remarques'
            className='mt-md'
            error={errors?.note_step_7 && errors.note_step_7.message}
            validation={max2500Validation}
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        )}
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='mb-3'>vérifiez vos données chiffres</h2>
        <p className='form-subtitle'>
          Ce récapitulatif vous permet de vérifier les chiffres que vous avez renseignés. Il vous indique le montant de
          rentabilité (EBE) retraitée issue du calcul. L'évaluation étant notamment basée sur l'EBE retraitré, vérifier
          sa cohérence et au besoin, ajustez-le en corrigeant vos chiffres renseignés en étape 4 à 7.
        </p>
        <div className='recap-ebe'>
          <div className='recap-row recap-bold'>
            <p>EBE retraité</p>
            <p>{formatNumberWithSpaces(ebe, true)}€</p>
          </div>
          <div className='recap-header-first'>Obtenu ainsi :</div>
          <div className='recap-row'>
            <p className='text-gold'>Chiffre d'affaires</p>
            <p className='text-gold'>{formatNumberWithSpaces(ca, true)}€</p>
          </div>
          {estimationValues?.ca?.total_commissions_nettes && (
            <div className='recap-row'>
              <p className='text-gold'>Commissions nettes</p>
              <p className='text-gold'>
                {formatNumberWithSpaces(estimationValues?.ca?.total_commissions_nettes, true)}€
              </p>
            </div>
          )}
          <div className='recap-header'>
            <div className='minus' />
          </div>
          <div className='recap-row'>
            <p>Achat marchandises & mat° premières</p>
            <p>{formatNumberWithSpaces(estimationValues?.charges?.achat_marchandise, true)}€</p>
          </div>
          <div className='recap-row'>
            <p>Autres achats & charges externes</p>
            <p>{formatNumberWithSpaces(estimationValues?.charges?.total_charges_externes, true)}€</p>
          </div>
          <div className='recap-row'>
            <p>Charges externes majorées</p>
            <p>{formatNumberWithSpaces(inputsWatcher?.majoration_charges_externes, true)}€</p>
          </div>
          <div className='recap-row'>
            <p>Impôts & taxes (hors IS)</p>
            <p>{formatNumberWithSpaces(estimationValues?.impots_taxes?.total_impots, true)}€</p>
          </div>
          <div className='recap-row'>
            <p>Rémunérations annuelles</p>
            <p>{formatNumberWithSpaces(estimationValues?.remunerations?.salaires, true)}€</p>
          </div>
          <div className='recap-row'>
            <p>Charges sociales annuelles</p>
            <p>{formatNumberWithSpaces(estimationValues?.remunerations?.charges_sociales, true)}€</p>
          </div>
          <div className='recap-row'>
            <p>Autres charges et redevances</p>
            <p>{formatNumberWithSpaces(estimationValues?.redevance?.redevance, true)}€</p>
          </div>
          <div className='recap-header'>
            <div className='minus' />
            <div className='minus-plus' />
          </div>
          <h2 className='text-gold p-0 mb-md'>Retraitements</h2>
          <div className='recap-row'>
            <p className='text-gold'>CSG crds exploitant</p>
            <p className='text-gold'>
              {formatNumberWithSpaces(estimationValues?.impots_taxes?.csg_crds_exploitant, true)}€
            </p>
          </div>
          <div className='recap-row'>
            <p className='text-gold'>Rém° annuelle dirigeant(s)</p>
            <p className='text-gold'>{formatNumberWithSpaces(inputsWatcher?.salaire_gerant_annuel, true)}€</p>
          </div>
          <div className='recap-row'>
            <p className='text-gold'>Charges soc° annuelles dirigeant(s)</p>
            <p className='text-gold'>
              {formatNumberWithSpaces(inputsWatcher?.charges_sociales_gerant_annuelles, true)}€
            </p>
          </div>
          <div className='recap-row'>
            <p className='text-gold'>Compléments de rém° dirigeant(s)</p>
            <p className='text-gold'>{formatNumberWithSpaces(inputsWatcher?.charges_ext_exp, true)}€</p>
          </div>
          <div className='recap-row'>
            <p className='text-gold'>Remontées holding dirigeant(s)</p>
            <p className='text-gold'>{formatNumberWithSpaces(inputsWatcher?.remontees_holding_dirigeant, true)}€</p>
          </div>
          <div className='recap-header-last'>
            <LightBulb width='35px' />
            Moduler vos retraitements influe directement sur l'EBE retraité
          </div>
          <div className='recap-row'>
            <p>Le résultat net est de</p>
            <p>{formatNumberWithSpaces(estimationValues?.resultat_net?.resultat_net_comptable, true)}€</p>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default Retraitements;
