import React from 'react';
import { ReactComponent as Icon } from '../../../../../../assets/homepage/icons/guideline/medal.svg';

function Medal({ width = '40px', color = '#5B4E70', className }) {
  return (
    <Icon style={{ '--color': color, 'fill': 'var(--color)' }} width={width} height={width} className={className} />
  );
}

export default Medal;
