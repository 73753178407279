import './HomepageTextImg.css';
import React from 'react';

function HomepageTextImg({ text1, text2, img, imgAlt = '' }) {
  return (
    <div className='homepage-text-img-container'>
      <p className='text-orange'>{text1}</p>
      <p>{text2}</p>
      <img src={img} alt={imgAlt} />
    </div>
  );
}

export default HomepageTextImg;
