import { ReactComponent as Icon } from '../../../../../assets/icons/general/warning.svg';
import React from 'react';

function Warning({ width = '15px', fill = 'var(--white)', onClick, className = '' }) {
  return (
    <Icon
      width={width}
      height={width}
      fill={fill}
      className={className + (onClick ? ' clickable-icon' : '')}
      onClick={onClick}
    />
  );
}

export default Warning;
