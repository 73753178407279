import React, { useEffect, useRef } from 'react';
import { defaultValidation } from '../../../../utils/formValidation/FormValidation';
import './Input.css';
import Magnifier from '../../../atoms/icons/general/magnifier/Magnifier.js';
import Warning from '../../../atoms/icons/general/warning/Warning';
import { toggleCollapseInput } from '../../../../utils/Utils.js';

// icon : euro / percent / search
function Input({
  label,
  name,
  type = 'text',
  register,
  defaultValue,
  validation = defaultValidation,
  error,
  icon,
  placeholder,
  visible,
  bgColor,
  tip,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  children,
  preventAutoComplete,
  value,
  disabled,
  className = '',
  shouldStickBottom = true, //false if the input should be aligned to the top of the row
}) {
  const inputRegister = register && (visible || visible === undefined) ? register(name, validation) : null;

  const ref = useRef(null);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseInput(ref.current, visible, 0);
  }, [visible]);

  return (
    <>
      <div className={`w-100 d-flex ${className} ${shouldStickBottom ? '' : 'mt-0 mb-auto'}`} ref={ref}>
        <div style={{ '--bg-color': bgColor }} className={`field-container ${bgColor ? 'filled-input' : ''}`}>
          <label className='form-label' htmlFor={name}>
            {label}
          </label>
          <p className={`label-tip ${tip ? '' : ' d-none'}`}>{tip}</p>
          <span className={`input-container ${icon ? 'before-' + icon : ''} ${error ? ' with-error' : ''}`}>
            {icon === 'search' && <Magnifier className='before-search-content' color='var(--dark-blue-alt)' />}
            <input
              disabled={(visible !== undefined && !visible) || disabled}
              placeholder={placeholder}
              className={icon ? `before-${icon}` : ''}
              id={name}
              value={value !== undefined ? value : undefined}
              autoComplete={preventAutoComplete ? 'off' : 'on'}
              type={type}
              {...inputRegister}
              onChange={e => {
                inputRegister && inputRegister.onChange(e);
                onChange && onChange(e);
              }}
              onFocus={e => {
                onFocus && onFocus(e);
              }}
              onBlur={e => {
                onBlur && onBlur(e);
              }}
              defaultValue={type === 'file' ? '' : defaultValue}
              onKeyDown={onKeyDown}
            />
            {children}
            {error && (
              <div className='form-error'>
                <Warning /> <p>{error}</p>
              </div>
            )}
          </span>
        </div>
      </div>
    </>
  );
}

export default Input;
