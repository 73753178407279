import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import { decimalValidation } from '../../../../../utils/formValidation/FormValidation.js';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import Select from '../../../../molecules/formComponents/select/Select';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import Info from '../../../../atoms/icons/general/info/Info';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import { getSelectValue, removeSpaces } from '../../../../../utils/Utils.js';
import { fetchDropdownValuesApi } from '../../../../../api/ParcoursApi.js';
import AppContext from '../../../../../context/AppContext.js';

function InfosComplementaires({ index }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
  const [dropdownsValues, setDropdownsValues] = useState({});
  const Form = { watch, setValue, register };

  const {
    showTooltip,
    step,
    submitFormsMc,
    postValues,
    getStepToGo,
    setGoToStep,
    setStep,
    fillSelectsFromEstimationValues,
    fillInputsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
    fillDropdownsValues();
  }, [step, estimationValues]);

  useEffect(() => {
    fillSelectsFromEstimationValues(
      'infos_complementaires',
      ['logement_inclus', 'stationnement_facile', 'accessibilite_pmr', 'equipement_exterieur'],
      setValue,
      estimationValues
    );

    fillInputsFromEstimationValues(
      'coef_ponderation',
      ['coef_1', 'coef_2', 'coef_3'],
      setValue,
      estimationValues.infos_complementaires
    );
    setValue('note_step_9', estimationValues?.notes?.note_step_9);
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      infos_complementaires: {
        accessibilite_pmr: { id: getSelectValue(values.accessibilite_pmr) },
        equipement_exterieur: { id: values.equipement_exterieur },
        logement_inclus: { id: values.logement_inclus },
        stationnement_facile: { id: values.stationnement_facile },
        coef_ponderation: {
          coef_1: removeSpaces(values.coef_1),
          coef_2: removeSpaces(values.coef_2),
          coef_3: removeSpaces(values.coef_3),
        },
      },
      notes: {
        note_step_9: values.note_step_9,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    }
  }

  async function fillDropdownsValues() {
    const values = {};
    try {
      values.accessibilite_pmr = (await fetchDropdownValuesApi('accessibilite_pmr')).data;
    } catch (error) {
      console.error(error);
    }
    setDropdownsValues(values);
  }

  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
        <fieldset className='borderless-fieldset'>
          <h2>informations complémentaires</h2>
          <Select
            label='accessibilité pmr (personne à mobilité réduite)'
            error={errors?.accessibilite_pmr && errors.accessibilite_pmr.message}
            name='accessibilite_pmr'
            register={register}>
            {dropdownsValues.accessibilite_pmr?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
          <Radio
            direction='column'
            label="le local dispose-t-il d'une terrasse / jardin / terrain / piscine ?"
            register={register}
            error={errors?.equipement_exterieur && errors.equipement_exterieur.message}
            name='equipement_exterieur'
            content={[
              {
                label: 'Sans objet ou locaux en étage uniquement',
                value: 1,
              },
              {
                label: 'Non',
                value: 2,
              },
              {
                label: 'Oui',
                value: 3,
              },
            ]}
          />
          <div className='row-1000 mb-sm'>
            <Radio
              direction='column'
              label='un logement habitable est-il inclus dans le local ?'
              register={register}
              error={errors?.logement_inclus && errors.logement_inclus.message}
              name='logement_inclus'
              content={[
                {
                  label: 'Non',
                  value: 1,
                },
                {
                  label: 'Oui',
                  value: 2,
                },
              ]}
            />
            <Radio
              direction='column'
              label='la clientèle peut-elle stationner facilement pour accéder au local ?'
              register={register}
              error={errors?.stationnement_facile && errors.stationnement_facile.message}
              name='stationnement_facile'
              content={[
                {
                  label: 'Non',
                  value: 1,
                },
                {
                  label: 'Oui',
                  value: 2,
                },
              ]}
            />
          </div>
        </fieldset>
        <fieldset className='borderless-fieldset'>
          <h2>notes et remarques</h2>
          <Textarea
            label="vous pouvez ajouter ici des notes, qui apparaîtront sur le rapport d'estimation"
            register={register}
            validation=''
            name='note_step_9'
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        </fieldset>
        <fieldset className='borderless-fieldset'>
          <h2>coefficients de pondération</h2>
          <div className='form-subtitle'>
            A appliquer sur les valeurs obtenues à l'issue de l'estimation initiale{' '}
            <Info onClick={() => showTooltip('Tutocoefponderateurfinalmurscommerciaux.jpeg')} />
          </div>
          <NumberInput
            useForm={Form}
            icon='percent'
            label="fourchette de taux d'effort théorique"
            name='coef_1'
            error={errors?.coef_1 && errors.coef_1.message}
            validation={decimalValidation}
          />
          <NumberInput
            useForm={Form}
            icon='percent'
            label='fourchette de loyer effectif actuel'
            name='coef_2'
            error={errors?.coef_2 && errors.coef_2.message}
            validation={decimalValidation}
          />
          <NumberInput
            useForm={Form}
            icon='percent'
            label='fourchette de rentabilité attendue'
            name='coef_3'
            error={errors?.coef_3 && errors.coef_3.message}
            validation={decimalValidation}
          />
        </fieldset>
      </form>
    </>
  );
}

export default InfosComplementaires;
