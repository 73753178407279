import './HomepageCard.css';
import React from 'react';

function HomepageCard({ number, text, title }) {
  return (
    <div className='homepage-card-container'>
      <div className='homepage-card-number' data-number={number}>
        {number}
      </div>
      <p>{title}</p>
      <p>{text}</p>
    </div>
  );
}

export default HomepageCard;
