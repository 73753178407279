import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';
import './TextHr.css';
import React, { useEffect, useRef } from 'react';

function TextHr({
  children,
  id,
  className = '',
  fontSize = '1.5rem',
  colors = 'transparent, var(--gold), var(--gold), var(--gold), transparent',
}) {
  const ref = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const maxWidth = getMaxWidth(
      [...ref.current.children].map(child => child.offsetWidth),
      containerRef.current.offsetWidth * 0.8 - 20
    );
    ref.current.style.width = maxWidth + 25 + 'px';
  }, []);

  function getMaxWidth(wordsLength, max) {
    let start = 0;
    let end = 0;
    let sum = 0;
    let maxSum = 0;

    while (end < wordsLength.length) {
      sum += wordsLength[end];

      while (sum > max) {
        sum -= wordsLength[start];
        start++;
      }

      if (sum > maxSum) maxSum = sum;

      end++;
    }

    return maxSum;
  }

  return (
    <div
      className={'text-hr-container ' + className}
      style={{ '--font-size': fontSize, '--colors': colors }}
      ref={containerRef}>
      {id != undefined && <div className='text-hr-id' id={id} />}
      <div className='text-hr-side' />
      <h2 className='text-hr-content' ref={ref}>
        {children.split(' ').map((word, key) => (
          <span key={key}>
            {word}
            {key !== children.split(' ').length - 1 && <>&nbsp;</>}
          </span>
        ))}
      </h2>
    </div>
  );
}

export default TextHr;
