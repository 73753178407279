import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  config => {
    const uuid = localStorage.getItem('uuid');
    if (uuid) {
      config.headers['Authorization'] = 'Bearer ' + uuid;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
