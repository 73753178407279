import React from 'react';
import { ReactComponent as Icon } from '../../../../../../assets/homepage/icons/guideline/puzzle.svg';

function Puzzle({ width = '40px', color = '#857261', className }) {
  return (
    <Icon style={{ '--color': color, 'fill': 'var(--color)' }} width={width} height={width} className={className} />
  );
}

export default Puzzle;
