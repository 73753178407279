import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatNumberWithSpaces, removeSpaces, uploadImage } from '../../../../utils/Utils';
import CaseInput from '../../../molecules/formComponents/caseInput/CaseInput';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';
import CardWithHeaders from '../../../molecules/cards/cardWithHeaders/CardWithHeaders';
import Card from '../../../molecules/cards/card/Card';
import Warning from '../../../atoms/icons/general/warning/Warning';
import Trash from '../../../atoms/icons/general/trash/Trash';
import DoubleInput from '../../../molecules/formComponents/doubleInput/DoubleInput';
import Input from '../../../molecules/formComponents/input/Input';
import { PlanFinancementContext } from '../planFinancementContext/PlanFinancementContext';
import axios from 'axios';
import UnfilledButton from '../../../molecules/buttons/unfilledButton/UnfilledButton';
import AppContext from '../../../../context/AppContext';
import Plus from '../../../atoms/icons/general/plus/Plus';

const PlanFinancementMurs = forwardRef(({ componentRef, visible }, ref) => {
  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const Form = { watch, register, setValue };
  const inputsListener = watch();

  const [autresPostes, setAutresPostes] = useState([]);
  const [autresPostesWarningVisible, setAutresPostesWarningVisible] = useState(false);
  const [nameError, setNameError] = useState('');
  const [inputsVisible, setInputsVisible] = useState(new Array(10).fill(true));
  const [removeOnPdf, setRemoveOnPdf] = useState(false);

  const { setModalVisible, setModalContent, showNotification } = useContext(AppContext);

  const { id_user, setLoaderVisible, plan, typePlan, fetchPlansFinancement, plansFinancement, estimationData } =
    useContext(PlanFinancementContext);

  useEffect(() => {
    setTimeout(() => {
      if (!getValues('ratio_frais_notaire')) setValue('ratio_frais_notaire', 7.5);
      if (estimationData.typePlan === 1 && !getValues('loyer')) setValue('loyer', estimationData.extra.loyer);
    }, 200);
  }, [visible, plan]);

  useEffect(() => {
    if (typePlan === 1 && Object.keys(plan).length) {
      for (const [key, value] of Object.entries(plan)) {
        if (key !== 'autres') setValue(key, value);
      }
      let tmp = plan.autres;
      tmp.forEach(element => (element.value = formatNumberWithSpaces(element.value)));
      setAutresPostes(tmp);
    } else {
      let values = getValues();
      Object.keys(values).forEach(element => setValue(element, ''));
      setAutresPostes([]);
    }
  }, [plan]);

  useEffect(() => {
    function calcTotalAFinancer(inputsListener) {
      let tmp = (({ prix_murs, frais_notaire, travaux }) => ({
        prix_murs,
        frais_notaire,
        travaux,
      }))(inputsListener);
      tmp = Object.values(tmp);

      let result = Math.round(tmp.reduce((a, b) => removeSpaces(a) + removeSpaces(b)));
      autresPostes.forEach(element => (result += removeSpaces(element.value)));
      if (removeSpaces(getValues('total_a_financer')) !== result) {
        setValue('total_a_financer', result);

        const ratio_apport_financier = removeSpaces(inputsListener.ratio_apport_financier);
        setValue(
          'apport_financier',
          result && ratio_apport_financier ? Math.round(result * ratio_apport_financier * 0.01) : '0'
        );
      }
    }

    function calcMontantAEmprunter(inputsListener) {
      const result = removeSpaces(inputsListener.total_a_financer) - removeSpaces(inputsListener.apport_financier);
      if (result !== removeSpaces(inputsListener.montant_a_emprunter))
        setValue('montant_a_emprunter', result ? result : '0');
    }

    function calcTotalFinance(inputsListener) {
      const result = removeSpaces(inputsListener.apport_financier) + removeSpaces(inputsListener.montant_a_emprunter);
      if (result !== removeSpaces(inputsListener.total_finance)) setValue('total_finance', result ? result : '0');
    }

    function calcRbsCredit(inputsListener) {
      let taux_emprunt = removeSpaces(inputsListener.taux_emprunt);
      const montant_a_emprunter = removeSpaces(inputsListener.montant_a_emprunter);
      const duree_pret = removeSpaces(inputsListener.duree_pret);
      taux_emprunt = taux_emprunt * 0.01;
      let result = Math.round((montant_a_emprunter * taux_emprunt) / (1 - Math.pow(1 + taux_emprunt, -duree_pret)));
      result = result ? result : 0;
      if (removeSpaces(inputsListener.rbs_credit) !== result && duree_pret) setValue('rbs_credit', result);
    }

    function calcBeneficePerte(inputsListener) {
      const result = removeSpaces(inputsListener.loyer) - removeSpaces(inputsListener.rbs_credit);
      if (result !== removeSpaces(inputsListener.benefice_perte)) setValue('benefice_perte', result ? result : '0');
    }

    if (visible) {
      calcTotalAFinancer(inputsListener);
      calcMontantAEmprunter(inputsListener);
      calcTotalFinance(inputsListener);
      calcBeneficePerte(inputsListener);
      calcRbsCredit(inputsListener);
    }
  }, [inputsListener]);

  useImperativeHandle(ref, () => ({
    setName(value) {
      setValue('name', value);
    },
    resetAutres() {
      setAutresPostes([]);
    },
    getName() {
      return getValues('name');
    },
    preparePdf(plan, callback) {
      preparePdf(plan, callback);
    },
  }));

  function addAutresPostes() {
    document.getElementById('autres_postes_murs_label').focus();
    if (getValues('autres_postes_murs_label') !== '') {
      setAutresPostes([
        ...autresPostes,
        {
          label: getValues('autres_postes_murs_label'),
          value: getValues('autres_postes_murs_value'),
          type: 'postes_murs',
        },
      ]);
      clearAutresPostes();
    } else setAutresPostesWarningVisible(true);
  }

  function deleteAutresPostes(index) {
    if (autresPostes.length > 0) setAutresPostes(array => array.filter((el, i) => i !== index));
  }

  function clearAutresPostes() {
    setValue('autres_postes_murs_label', '');
    setValue('autres_postes_murs_value', '');
  }

  function calcFraisNotaireFromRatio(e) {
    const ratio_frais_notaire = e.target.value;
    let result = '0';
    if (removeSpaces(inputsListener.prix_murs) !== 0)
      result = Math.round(removeSpaces(inputsListener.prix_murs) * ratio_frais_notaire * 0.01);

    if (result !== removeSpaces(inputsListener.frais_notaire)) setValue('frais_notaire', result);
  }

  function calcRatioFromFraisNotaire(e) {
    let frais_notaire = e.target.value;
    let result = '0';
    if (removeSpaces(inputsListener.prix_murs) !== 0)
      result = Math.round((removeSpaces(frais_notaire) / removeSpaces(inputsListener.prix_murs) / 0.01) * 10) / 10;
    if (result !== removeSpaces(inputsListener.ratio_frais_notaire)) setValue('ratio_frais_notaire', result);
  }

  function calcFraisNotaireFromPrixMurs(e) {
    const prix_murs = e.target.value;
    const ratio_frais_notaire = removeSpaces(inputsListener.ratio_frais_notaire);
    setValue(
      'frais_notaire',
      prix_murs && ratio_frais_notaire ? Math.round(prix_murs * ratio_frais_notaire * 0.01) : '0'
    );
  }

  function calcApportFinancierFromRatio(e) {
    const ratio_apport_financier = e.target.value;
    let result = '0';
    if (removeSpaces(inputsListener.total_a_financer) !== 0)
      result = Math.round(removeSpaces(inputsListener.total_a_financer) * ratio_apport_financier * 0.01);
    if (result !== removeSpaces(inputsListener.apport_financier)) setValue('apport_financier', result);
  }

  function calcRatioFromApportFinancier(e) {
    let apport_financier = e.target.value;
    let result = '0';
    if (removeSpaces(inputsListener.total_a_financer) !== 0)
      result =
        Math.round((removeSpaces(apport_financier) / removeSpaces(inputsListener.total_a_financer) / 0.01) * 10) / 10;
    if (result !== removeSpaces(inputsListener.ratio_apport_financier)) setValue('ratio_apport_financier', result);
  }

  async function preparePdf(plan, callback = () => {}) {
    const deletablesEntries = [
      'prix_murs',
      'ratio_frais_notaire',
      'frais_notaire',
      'travaux',
      'ratio_apport_financier',
      'apport_financier',
      'montant_a_emprunter',
      'duree_pret',
      'taux_emprunt',
    ];
    let tmp = inputsVisible;
    setLoaderVisible(true);

    for (let i = 0; i < deletablesEntries.length; i++) {
      if (!removeSpaces(getValues(deletablesEntries[i]))) {
        tmp[i] = false;
      }
    }
    document.getElementById('plan-financement-murs').classList.add('replace-shadows');
    document.getElementsByClassName('plan-financement-div')[1].style.overflowY = '';
    setInputsVisible(tmp);
    setRemoveOnPdf(true);

    setTimeout(() => {
      uploadImage(
        document.getElementById('plan-financement-murs'),
        localStorage.getItem('token'),
        'plan-financement/image-pdf',
        {
          id_plan: plan.id,
          type: 'murs',
        },
        () => {
          preparePdfCallback();
          callback();
        },
        'plan_financement'
      );
    }, 500);
  }

  function preparePdfCallback() {
    document.getElementById('plan-financement-murs').classList.remove('replace-shadows');
    setInputsVisible(new Array(9).fill(true));
    setRemoveOnPdf(false);

    if (localStorage.getItem('downloadPdf')) {
      setLoaderVisible(false);
      return;
    }

    if (localStorage.getItem('fromSynthese')) {
      localStorage.removeItem('fromSynthese');
      window.location.replace(process.env.REACT_APP_V1_URL + 'rapport-synthetique');
    } else {
      setLoaderVisible(false);
    }
  }

  async function submitForm(values) {
    let data = values;
    const name = values.name;

    for (const [key, value] of Object.entries(data)) {
      data[key] = removeSpaces(value);
    }
    data.autres = autresPostes;
    data.name = name;
    data.id_user = id_user;

    data.autres.forEach(element => {
      element.value = removeSpaces(element.value);
    });

    const deletablesEntries = ['autres_postes_murs_label', 'autres_postes_murs_value'];
    deletablesEntries.forEach(element => delete data[element]);

    if (id_user) {
      if (estimationData.typePlan === 1) data.numEstimation = estimationData.numEstimation;

      try {
        if (Object.keys(plan).length && !localStorage.getItem('planFinancement')) {
          await axios.put(
            process.env.REACT_APP_API_URL + '/plan-financement/murs/' + plan.id,
            {
              ...data,
              id_user: id_user,
              id: plan.id,
            },
            {
              headers: {
                Authorization: localStorage.getItem('token'),
              },
            }
          );

          showNotification('Le plan de financement ' + getValues('name') + ' a été modifié avec succès');

          setTimeout(() => {
            document.getElementById('plan-financement-start').scrollIntoView({ behavior: 'smooth' }, true);
          }, 250);
        } else {
          if (plansFinancement.filter(element => element.name === values.name).length) {
            setNameError('Ce nom existe déjà');
            document.getElementsByName('name')[1].scrollIntoView({ behavior: 'auto', block: 'center' }, true);
            return;
          }

          await axios.post(
            process.env.REACT_APP_API_URL + '/plan-financement/murs',
            {
              ...data,
              id_user: id_user,
            },
            {
              headers: {
                Authorization: localStorage.getItem('token'),
              },
            }
          );

          showNotification('Le plan de financement ' + getValues('name') + ' a été créé avec succès');

          setTimeout(() => {
            document.getElementById('plan-financement-start').scrollIntoView({ behavior: 'smooth' }, true);
          }, 250);
        }
        localStorage.removeItem('planFinancement');

        const result = await fetchPlansFinancement(data.name);

        if (estimationData.typePlan === 1) preparePdf(result);
      } catch (e) {
        showNotification('Une erreur est survenue', 'var(--red');
      }
    } else {
      setModalVisible(true);
      setModalContent({
        title: 'Inscription',
        content: (
          <>
            <div className='modal-text'>
              <p>Vous n'êtes actuellement connecté à aucun compte.</p>
              <p>
                Pour continuer votre plan de financement plus tard en conservant vos données déjà saisies, il vous
                suffit de vous inscrire ou de vous connecter
              </p>
            </div>
            <div className='modal-buttons'>
              <UnfilledButton
                to='/inscription?redirection=plan-financement'
                borderColor='var(--dark-blue)'
                hoverColor='var(--sky-blue)'>
                Inscription
              </UnfilledButton>
              <FilledButton to='/connexion?redirection=plan-financement'>Connexion</FilledButton>
            </div>
          </>
        ),
      });
      localStorage.setItem('planFinancement', JSON.stringify({ ...data, typePlan: 1 }));
    }
  }

  return (
    <div ref={el => (componentRef.current[0] = el)} className={'plan-financement-div' + (visible ? '' : ' d-none')}>
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          name='name'
          onChange={() => setNameError('')}
          register={register}
          label='Intitulé du plan de financement'
          setValue={setValue}
          error={(errors?.name && errors.name.message) || nameError}
        />
        <div id='plan-financement-murs'>
          <section className='row-1000 plan-financement-section'>
            <div className='w-100'>
              <CardWithHeaders
                padding='20px'
                paddingFooter='10px'
                header={<h2 className='form-h2 p-0 text-center'>Besoins</h2>}
                footer={
                  <CaseInput
                    name='total_a_financer'
                    useForm={Form}
                    label='TOTAL À FINANCER'
                    icon='euro'
                    bgColor='var(--sky-blue)'
                    color='white'
                  />
                }>
                <div className='card-content-financement'>
                  <div className={'deletable-input ' + (!inputsVisible[0] && 'd-none')}>
                    <CaseInput
                      name='prix_murs'
                      useForm={Form}
                      label='Prix des murs'
                      icon='euro'
                      onChange={calcFraisNotaireFromPrixMurs}
                    />
                  </div>
                  <div className={'deletable-input ' + (!inputsVisible[1] && 'd-none')}>
                    <CaseInput
                      name='ratio_frais_notaire'
                      useForm={Form}
                      label='Frais de notaire'
                      icon='percent'
                      onChange={calcFraisNotaireFromRatio}
                    />
                  </div>
                  <div className={'deletable-input ' + (!inputsVisible[2] && 'd-none')}>
                    <CaseInput
                      name='frais_notaire'
                      useForm={Form}
                      label='Montant des frais de notaire'
                      icon='euro'
                      onChange={calcRatioFromFraisNotaire}
                    />
                  </div>
                  <div className={'deletable-input ' + (!inputsVisible[3] && 'd-none')}>
                    <CaseInput name='travaux' useForm={Form} label='Travaux' icon='euro' />
                  </div>
                  {autresPostes.map((element, key) => {
                    return (
                      <div className='row-with-button' key={key}>
                        <CaseInput
                          useForm={{}}
                          name={'autres_postes.' + key}
                          label={element.label}
                          value={formatNumberWithSpaces(removeSpaces(element.value))}
                          onChange={e =>
                            setAutresPostes([
                              ...autresPostes.slice(0, key),
                              (autresPostes[key] = {
                                value: e.target.value,
                                label: autresPostes[key].label,
                                type: 'postes_murs',
                              }),
                              ...autresPostes.slice(key + 1),
                            ])
                          }
                        />
                        <div className={'trash-container ' + (removeOnPdf && 'd-none')}>
                          <Trash onClick={() => deleteAutresPostes(key)} />
                        </div>
                      </div>
                    );
                  })}
                  <div className={removeOnPdf ? 'd-none' : undefined}>
                    <DoubleInput
                      placeholder1='Ajouter un poste à financer'
                      name='autres_postes_murs_value'
                      name1='autres_postes_murs_label'
                      useForm={Form}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          addAutresPostes();
                        }
                      }}
                      onChange1={e => {
                        if (e.target.value && autresPostesWarningVisible) setAutresPostesWarningVisible(false);
                      }}
                    />
                    <div className='row'>
                      <div className={'warning-financement' + (autresPostesWarningVisible ? '' : ' d-none')}>
                        <Warning />
                        L'intitulé doit être renseigné
                      </div>
                      <div className='buttons-container-plan-financement'>
                        <Plus width='25px' onClick={addAutresPostes} />
                      </div>
                    </div>
                  </div>
                </div>
              </CardWithHeaders>
            </div>
            <div className='w-100'>
              <CardWithHeaders
                padding='20px'
                className='ressources-card'
                borderColor='var(--dark-blue-alt)'
                paddingFooter='10px'
                header={<h2 className='form-h2 p-0 text-center'>Ressources</h2>}
                footer={
                  <CaseInput
                    name='total_finance'
                    useForm={Form}
                    label='TOTAL FINANCÉ'
                    color='white'
                    icon='euro'
                    bgColor='var(--sky-blue)'
                  />
                }>
                <div>
                  <div className={'deletable-input ' + (!inputsVisible[4] && 'd-none')}>
                    <CaseInput
                      name='ratio_apport_financier'
                      useForm={Form}
                      label='Apport financier (% du total à financer)'
                      icon='percent'
                      onChange={calcApportFinancierFromRatio}
                    />
                  </div>
                  <div className={'deletable-input ' + (!inputsVisible[5] && 'd-none')}>
                    <CaseInput
                      name='apport_financier'
                      useForm={Form}
                      label="Montant de l'apport"
                      icon='euro'
                      onChange={calcRatioFromApportFinancier}
                    />
                  </div>
                  <div className={'deletable-input ' + (!inputsVisible[6] && 'd-none')}>
                    <CaseInput name='montant_a_emprunter' useForm={Form} label='Montant à emprunter' icon='euro' />
                  </div>
                  <div className={'deletable-input ' + (!inputsVisible[7] && 'd-none')}>
                    <CaseInput name='duree_pret' icon='' useForm={Form} label='Durée du prêt (années)' />
                  </div>
                  <div className={'deletable-input ' + (!inputsVisible[8] && 'd-none')}>
                    <CaseInput name='taux_emprunt' useForm={Form} label="Taux d'emprunt (TAEG)" icon='percent' />
                  </div>
                </div>
              </CardWithHeaders>
            </div>
          </section>
          <section className='plan-financement-section'>
            <Card bgColor='var(--sky-blue)' padding='25px 20px'>
              <h2 className='form-h2 p-0'>Le loyer perçu dans le local d'activité</h2>
            </Card>
            <div className='row p-md'>
              <CaseInput
                tip={estimationData.typePlan === 1 && 'Vous pouvez aussi renseigner un montant libre'}
                name='loyer'
                useForm={Form}
                label="Loyer annuel payé par l'occupant"
                icon='euro'
              />
            </div>
            <Card bgColor='var(--blue)' padding='25px 20px'>
              <h2 className='form-h2 p-0'>...Sert à rembourser le prêt sur l'achat des murs...</h2>
            </Card>
            <div className='row p-md'>
              <CaseInput
                name='rbs_credit'
                useForm={Form}
                label='Remboursement annuel du crédit (annuité capital + intérêts)'
                icon='euro'
              />
            </div>
            <Card bgColor='var(--dark-blue)' padding='30px'>
              <h2 className='form-h2 p-0'>...Et dégage un excédent bénéficiaire ou une perte à combler</h2>
            </Card>
            <div className='row p-md'>
              <CaseInput name='benefice_perte' useForm={Form} label='Bénéfice annuel ou perte annuelle' icon='euro' />
            </div>
          </section>
        </div>
        <div className='centered'>
          <FilledButton type='submit' padding='10px 25px' className='mb-md'>
            Valider et sauvegarder mes données
          </FilledButton>
        </div>
      </form>
    </div>
  );
});

export default PlanFinancementMurs;
