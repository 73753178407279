import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/header/rapport.svg';

function Rapport({ width = '30px', color = 'var(--dark-blue)', className }) {
  return (
    <Icon
      style={{ '--color': color, 'stroke': 'var(--color)', 'fill': 'var(--color)' }}
      width={width}
      height={width}
      className={className}
    />
  );
}

export default Rapport;
