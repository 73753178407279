import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import './ConseilsProsArticle.css';
import React, { useEffect, useState, useRef, useContext } from 'react';
import ArrowSm from '../../../components/atoms/icons/general/arrowSm/ArrowSm';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../../components/molecules/pageTitle/PageTitle';
import DOMPurify from 'dompurify';
import ReactDOMServer from 'react-dom/server';
import HtmlToReact, { Parser } from 'html-to-react';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import Cross from '../../../components/atoms/icons/general/cross/Cross';
import RapportsColorsCTA from '../../../components/atoms/icons/conseilsPros/rapportsColorsCTA/RapportsColorsCTA';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';
import ExpendableGroup from '../../../components/organisms/expendableGroup/ExpendableGroup';
import Eye from '../../../components/atoms/icons/general/eye/Eye';
import PDF from '../../../assets/rapportsExamples/exemple_rapport_fdc.pdf';
import Check from '../../../components/atoms/icons/general/check/Check';
import Linkedin from '../../../components/atoms/icons/share/linkedin/Linkedin';
import Facebook from '../../../components/atoms/icons/share/facebook/Facebook';
import Twitter from '../../../components/atoms/icons/share/twitter/Twitter';
import Email from '../../../components/atoms/icons/share/email/Email';
import InfiniteThumbnailsRow from '../../../components/organisms/infiniteThumbnailsRow/InfiniteThumbnailsRow';
import AppContext from '../../../context/AppContext';
import { fetchAllConseilsProsApi, fetchConseilProByUrlApi } from '../../../api/OutilsApi';
import { getPrettyText } from '../../../utils/Utils';

function ConseilsProsArticle() {
  const { createNotification } = useContext(AppContext);
  const [article, setArticle] = useState({
    id: '',
    title: '',
    content: '',
    created_at: '',
    updated_at: '',
    image_path: '',
    image_alt: '',
    title_doc: '',
    file_name: '',
    url: '',
  });
  const [articleAnchors, setArticleAnchors] = useState([]);
  const [hasOutilCTAShown, setHasOutilCTAShown] = useState(0);
  const [windowScroll] = useWindowScroll();
  const [FAQ, setFAQ] = useState({
    title: '',
    content: [],
  });
  const [suggestions, setSuggestions] = useState([]);
  const [activeLink, setActiveLink] = useState(null);
  const [isScrollPrevent, setIsScrollPrevent] = useState(false);

  const summaryRef = useRef(null);
  const outilCTASliderRef = useRef(null);
  const articleRef = useRef(null);
  const FAQRef = useRef(null);
  const { width } = useWindowSize();
  const magnet = document.getElementById('leadMagnet');
  const { setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();
  const { articleUrl } = useParams();

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchAllData();

    setTimeout(() => {
      if (document.getElementById('rapport_container'))
        document.getElementById('rapport_container').addEventListener('click', openRapport);
    }, 2000);

    return () => {
      if (document.getElementById('rapport_container'))
        document.getElementById('rapport_container')?.removeEventListener('click', openRapport);
    };
  }, []);

  useEffect(() => {
    setAnchorsFromArticle();
  }, [article]);

  useEffect(() => {
    if (magnet && !hasOutilCTAShown && windowScroll.y >= magnet.offsetTop) {
      setHasOutilCTAShown(true);
      showOutilCTA();
    }

    const tmp = document.querySelector('.article-aside li.active');
    if (tmp && activeLink !== tmp) setActiveLink(tmp);
  }, [windowScroll]);

  useEffect(() => {
    handleSummaryScroll();
  }, [activeLink]);

  async function fetchAllData() {
    try {
      await fetchArticle();
      await fetchSuggestions();
      setAppLoaderVisible(false);
    } catch (error) {
      navigate('/404');
    }
  }

  function getPrettyArticle(article) {
    const processNodeDefinitions = HtmlToReact.ProcessNodeDefinitions();
    const processingInstructions = [
      {
        shouldProcessNode: function (node) {
          return node.name && node.name === 'img';
        },
        processNode: function (node) {
          return React.createElement('img', {
            id: node.attribs.id,
            src: `${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${article.url}/${
              node.attribs.src.split('/').reverse()[0]
            }`,
            className: node.attribs.class,
            alt: node.attribs.alt,
            onError: ({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
              currentTarget.alt = 'Image non disponible';
            },
          });
        },
      },
      {
        shouldProcessNode: function (node) {
          return node.name && node.name === 'hr';
        },
        processNode: function (node) {
          return React.createElement('hr', { className: 'hr-article' });
        },
      },
      {
        shouldProcessNode: function (node) {
          return node.name && node.name === 'button';
        },
        processNode: function (node) {
          return React.createElement(
            'a',
            { target: '_blank', className: 'button filled-button article-button', href: '/debut-parcours' },
            node.children[0].data
          );
        },
      },
      {
        shouldProcessNode: function (node) {
          return (
            node.name &&
            node.attribs &&
            node.attribs.class &&
            node.name === 'div' &&
            node.attribs.class.includes('container')
          );
        },
        processNode: function (node) {
          return React.createElement('div', { id: 'cta_container' }, <EstimationCTA />);
        },
      },
      {
        shouldProcessNode: function () {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ];
    const isValidNode = function () {
      return true;
    };

    const htmlToReactParser = new Parser();
    const reactComponent = htmlToReactParser.parseWithInstructions(
      article.content,
      isValidNode,
      processingInstructions
    );
    article.content = ReactDOMServer.renderToStaticMarkup(reactComponent);

    return article;
  }

  async function fetchArticle() {
    try {
      const article = (await fetchConseilProByUrlApi(articleUrl)).data;
      if (!article) return navigate('/404');

      article.title = getPrettyText(article.title);
      article.image_path = 'Image_de_tete_Le_calendrier.jpeg';

      setFaqFromArticle(article.faq);

      delete article.faq;

      setArticle(getPrettyArticle(article));
    } catch (error) {
      throw error;
    }
  }

  async function setFaqFromArticle(faq) {
    setFAQ({
      title: getPrettyText(faq.faq_title),
      content: [
        {
          title: getPrettyText(faq.faq_question_1.name),
          answer: getPrettyText(faq.faq_question_1.content),
        },
        {
          title: getPrettyText(faq.faq_question_2.name),
          answer: getPrettyText(faq.faq_question_2.content),
        },
        {
          title: getPrettyText(faq.faq_question_3.name),
          answer: getPrettyText(faq.faq_question_3.content),
        },
      ],
    });
  }

  async function fetchSuggestions() {
    try {
      throw new Error('Not implemented');
      let suggestions = (await fetchAllConseilsProsApi()).data;
      const article = suggestions.find(suggestion => suggestion.url === articleUrl);

      suggestions = suggestions
        .filter(suggestion => suggestion.tag === article.tag)
        .map(suggestion => {
          return {
            title: getPrettyText(suggestion.title),
            tag: getPrettyText(suggestion.tag),
            picture: `process.env.REACT_APP_API_URL/assets/ConseilsPros/${suggestion.url}/miniature/${suggestion.picture}`,
            link: `/conseils-pros/${suggestion.url}`,
            linkText: "Lire l'article",
          };
        });

      setSuggestions(suggestions);
    } catch (error) {
      createNotification(
        'Une erreur est survenue lors de la récupération des suggestions',
        'var(--red)',
        'var(--dark-blue)'
      );
    }
  }

  function handleSummaryScroll() {
    if (!activeLink || !summaryRef.current) return;
    const childRect = activeLink.getBoundingClientRect();
    const containerRect = summaryRef.current.getBoundingClientRect();

    if (childRect.top < containerRect.top) summaryRef.current.scrollTop += childRect.top - containerRect.top - 30;
    if (childRect.bottom > containerRect.bottom)
      summaryRef.current.scrollTop += childRect.bottom - containerRect.bottom + 20;
  }

  function showOutilCTA() {
    if (outilCTASliderRef.current) {
      outilCTASliderRef.current.style.display = 'block';
      outilCTASliderRef.current.style.right = '20px';
    }
  }

  function hideOutilCTA() {
    if (outilCTASliderRef.current) {
      outilCTASliderRef.current.style.opacity = '0';
    }
    setTimeout(() => {
      if (outilCTASliderRef.current) outilCTASliderRef.current.style.display = 'none';
    }, 500);
  }

  function setAnchorsFromArticle() {
    const children = [...Array.from(articleRef.current.children), ...Array.from(FAQRef.current.children)];
    const titles = children.filter(child => child.localName === 'h2');
    setArticleAnchors(
      titles.map((title, index) => {
        title.setAttribute('id', getTitleIdFromName(title.innerHTML));
        const top = title.offsetTop - 50;
        return {
          name: getPrettyText(title.innerHTML),
          link: getTitleIdFromName(title.innerHTML),
          top: top,
          bottom: titles.length > index + 1 ? titles[index + 1].offsetTop - 50 : top + 1000000,
        };
      })
    );
  }

  function getTitleIdFromName(name) {
    return name
      .split(' ')
      .join('-')
      .replaceAll(/[^a-zA-Z0-9- ]/g, '');
  }

  function openRapport(e) {
    e.preventDefault();
    window.open(PDF, '_blank');
  }

  function preventAutoScroll() {
    setIsScrollPrevent(true);
    setTimeout(() => {
      setIsScrollPrevent(false);
    }, 1000);
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const EstimationCTA = () => (
    <div className='my-xl'>
      <h3 className='text-xl outfit-bold centered mb-md'>Estimez en ligne une affaire</h3>
      <div className='estimation-cta-container'>
        <a href={PDF} className='estimation-cta-img-container' id='rapport_container'>
          <div
            className='button inversion-button article-button text-lg'
            style={{
              '--padding': '3px 10px',
              '--hover-color': 'var(--white)',
              '--bg-color': 'var(--blue)',
            }}>
            <Eye />
          </div>
          <img src={require('../../../assets/rapportsExamples/miniature_rapport_fdc.webp')} />
        </a>
        <div className='flex f-column space-between gap-sm mx-auto'>
          <p className='cta-title centered'>Découvrez nos modules d'estimation&nbsp;:</p>
          <div className='mx-auto'>
            <p>
              <span>
                <Check />
                &nbsp;&nbsp;Fonds de commerce
              </span>
              <br />
              <span>
                <Check />
                &nbsp;&nbsp;Titres de société
              </span>
              <br />
              <span>
                <Check />
                &nbsp;&nbsp;Murs commerciaux
              </span>
            </p>
          </div>
          <div className='centered'>
            <a className='button filled-button article-button text-md' href='/debut-parcours'>
              Démarrer mon estimation
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            {
              name: 'Accueil',
              to: '/',
            },
            {
              name: 'Conseils: Conseils de pros',
              to: '/conseils-pros',
            },
            {
              name: article.title,
              to: '/conseils-pros/' + article.url,
            },
          ]}
        />
        <Banner
          title='Conseils de pros'
          subtitle={
            <Link className='link-with-arrow' to='/conseils-pros'>
              <ArrowSm /> Retour à la liste des conseils
            </Link>
          }
        />
        <PageTitle title={article.title} />
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
            currentTarget.alt = 'Image non disponible';
          }}
          className='article-main-picture mb-md'
          src={`${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${articleUrl}/miniature/${article.image_path}`}
        />
        <div className='centered mb-md'>
          <p className='text-sky-blue outfit-bold'>
            Publié le {article.created_at}
            {article.updated_at && (
              <span className='text-sky-blue outfit-bold'> et mis à jour le {article.updated_at}</span>
            )}
          </p>
        </div>
        <div className='outil-cta-slider' ref={outilCTASliderRef}>
          <div className='outil-cta-container'>
            <Cross width='20px' className='outil-cta-close' onClick={hideOutilCTA} />
            <div className='flex f-column gap-md centered'>
              <p className='centered'>
                Découvrez notre
                <br />
                <span className='outfit-bold'>{article.title_doc}</span>
              </p>
              <RapportsColorsCTA />
              <FilledButton
                padding='9px 15px'
                bgColor='var(--light-blue)'
                hoverColor='var(--light-blue)'
                color='var(--dark-blue)'>
                Télécharger notre outil
              </FilledButton>
            </div>
          </div>
        </div>
        <div className='flex space-between relative'>
          <aside className='article-aside' ref={summaryRef}>
            <h4 className='outfit-bold'>Dans cet article</h4>
            <ul className='article-nav f-column gap-md flex'>
              {articleAnchors.map((anchor, key) => (
                <li
                  key={key}
                  className={
                    !isScrollPrevent && windowScroll.y >= anchor.top && windowScroll.y < anchor.bottom ? 'active' : ''
                  }>
                  <HashLink onClick={preventAutoScroll} to={'#' + anchor.link}>
                    {anchor.name}
                  </HashLink>
                </li>
              ))}
            </ul>
          </aside>
          <div className='conseils-pros-container'>
            <div
              className='article-container'
              ref={articleRef}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}></div>
            <div className='article-container' ref={FAQRef}>
              <hr className='hr-article' />
              <h2>F.A.Q</h2>
              <p>{FAQ.title}</p>
              <div className={width > 576 ? 'mx-lg' : ''}>
                <ExpendableGroup
                  data={FAQ.content.map(row => {
                    return { title: row.title, content: <p>{row.answer}</p> };
                  })}
                />
              </div>
            </div>
          </div>
          <div className='aside-container'>
            <aside className='article-aside'>
              <h4 className='outfit-bold'>Découvrez nos outils</h4>
              <p className='mb-md'>
                Des outils pratiques pour l'estimation, la cession et l'acquisition de fonds, titres et murs commerciaux
              </p>
              <Link className='link link-with-arrow mb-md' to='/outils-pratiques'>
                Je découvre les outils pratiques&nbsp;
                <ArrowSm rotation='180deg' />
              </Link>
              <Link className='link link-with-arrow mb-lg' to='/outils-pratiques'>
                J'estime une affaire ou des murs&nbsp;
                <ArrowSm rotation='180deg' />
              </Link>
              <h4 className='outfit-bold'>Partagez cet article !</h4>
              <p>Cet article vous a plu&nbsp;? Partagez-le&nbsp;!</p>
              <div className='row space-between mt-sm'>
                <FilledButton
                  padding='5px'
                  borderRadius='7px'
                  bgColor='#0077B5'
                  hoverColor='#0077B5'
                  target='_blank'
                  to={
                    'https://www.linkedin.com/sharing/share-offsite/?url=' +
                    process.env.REACT_APP_URL +
                    '/conseils-pros/' +
                    article.url
                  }
                  rel='nofollow'>
                  <Linkedin />
                </FilledButton>
                <FilledButton
                  padding='5px'
                  borderRadius='7px'
                  bgColor='#1761AB'
                  hoverColor='#1761AB'
                  target='_blank'
                  to={
                    'https://www.facebook.com/sharer/sharer.php?u=' +
                    process.env.REACT_APP_URL +
                    '/conseils-pros/' +
                    article.url
                  }
                  rel='nofollow'>
                  <Facebook />
                </FilledButton>
                <FilledButton
                  padding='5px'
                  borderRadius='7px'
                  bgColor='#2D9AFF'
                  hoverColor='#2D9AFF'
                  target='_blank'
                  to={'https://twitter.com/share?url=' + process.env.REACT_APP_URL + '/conseils-pros/' + article.url}
                  rel='nofollow'>
                  <Twitter />
                </FilledButton>
                <FilledButton
                  padding='5px'
                  borderRadius='7px'
                  bgColor='var(--gold)'
                  hoverColor='var(--gold)'
                  target='_blank'
                  to={
                    'mailto:?Subject=Article%20EstimerMonCommerce.fr&body=Partage%20d%27un%20article%20sur%20EstimerMonCommerce.fr%20:%20' +
                    process.env.REACT_APP_URL +
                    '/conseils-pros/' +
                    article.url
                  }
                  rel='nofollow'>
                  <Email />
                </FilledButton>
              </div>
            </aside>
            <div className='top-scroller-container'>
              <div className='top-scroller' onClick={scrollToTop} />
            </div>
          </div>
        </div>
        <FilledButton className='mid-page-button' to='/debut-parcours'>
          Je démarre mon estimation
        </FilledButton>
      </section>
      <InfiniteThumbnailsRow
        title={<PageTitle title='DANS LA MÊME CATÉGORIE :' margin='20px auto' />}
        data={suggestions}
        margin='-15px auto 50px'
      />
    </div>
  );
}

export default ConseilsProsArticle;
