import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/header/cart.svg';

function Cart({ width = '20px', color = 'var(--blue)', className }) {
  return (
    <Icon style={{ '--color': color, 'stroke': 'var(--color)' }} width={width} height={width} className={className} />
  );
}

export default Cart;
