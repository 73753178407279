import './SimulateurCredit.css';
import React from 'react';
import Header from '../../../components/organisms/header/Header';
import Footer from '../../../components/organisms/footer/Footer';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import SimulateurCreditForm from '../../../components/forms/simulateurCreditForm/SimulateurCreditForm';

function SimulateurCredit() {
  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              to: '/',
              name: 'Accueil',
            },
            {
              to: '/simulateur-credit',
              name: 'Simulateur de crédit',
            },
          ]}
        />
        <Banner
          title='Simulateur de crédit'
          subtitle="Tableau d'amortissement personnalisé pour votre plan de financement"
        />
        <SimulateurCreditForm />
      </section>
    </>
  );
}

export default SimulateurCredit;
