import './RapportsExemples.css';
import React from 'react';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import RapportsRow from '../../../components/organisms/rapportsRow/RapportsRow';

function RapportsExemples() {
  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            { name: 'Accueil', to: '/' },
            { name: 'Estimer: Exemples de rapports', to: '/exemples-rapports' },
          ]}
        />
        <Banner
          title='Exemples de rapports'
          subtitle='Téléchargez nos exemples de rapports pour consulter leur contenu détaillé'
        />
      </section>
      <div>
        <RapportsRow />
      </div>
    </div>
  );
}

export default RapportsExemples;
