import './MesAbosFactures.css';
import Crown from '../../../../../components/atoms/icons/tarifs/crown/Crown';
import React, { useEffect, useState } from 'react';
import FilledButton from '../../../../../components/molecules/buttons/filledButton/FilledButton';
import { Link } from 'react-router-dom';
import ArrowSm from '../../../../../components/atoms/icons/general/arrowSm/ArrowSm';

function MesAbosFactures() {
  const [offre, setOffre] = useState({
    name: '',
    estimations: '',
    dateSouscription: '',
    dateRenouvellement: '',
  });

  useEffect(() => {
    fetchOffre();
  }, []);

  function fetchOffre() {
    const offre = {
      name: 'MAESTRO',
      // name: 'LIBERTY',
      // name: 'OPTIMUM',
      estimations: 'illimité',
      dateSouscription: '01/07/2022',
      dateRenouvellement: '01/07/2023',
    };

    setOffre(offre);
  }

  return (
    <section className='container'>
      <div className='row-1000'>
        <div className='col'>
          <div className={'offre-container ' + offre.name.toLowerCase()}>
            {offre.name === 'MAESTRO' && <Crown width='90px' className='offre-crown' />}
            <p>
              Abonnement en cours&nbsp;: <span className='outfit-black'>{offre.name}</span>
            </p>
            <p>
              Nombre d'estimations restantes&nbsp;: <span className='outfit-black'>{offre.estimations}</span>
            </p>
            <p>
              Date de souscription&nbsp;: <span className='outfit-black'>{offre.dateSouscription}</span>
            </p>
            <p>
              Date de renouvellement&nbsp;: <span className='outfit-black'>{offre.dateRenouvellement}</span>
            </p>
          </div>
        </div>
        <div className='col'>
          <div className='offre-buttons-container'>
            <Link className='link-with-arrow' to='/TODO'>
              Mes moyens de paiement&nbsp;
              <ArrowSm rotation='180deg' />
            </Link>
            <Link className='link-with-arrow' to='/TODO'>
              Mes factures&nbsp;
              <ArrowSm rotation='180deg' />
            </Link>
            <Link className='link-with-arrow' to='/TODO'>
              Choisir mon abonnement&nbsp;
              <ArrowSm rotation='180deg' />
            </Link>
            <Link className='link-with-arrow' to='/TODO'>
              Ne pas renouveler mon abonnement à échéance&nbsp;
              <ArrowSm rotation='180deg' />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MesAbosFactures;
