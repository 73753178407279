import React, { useContext, useEffect, useState, Fragment } from 'react';
import './Panier.css';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import Card from '../../../components/molecules/cards/card/Card';
import { useCart } from '../../../context/CartContext';
import Trash from '../../../components/atoms/icons/general/trash/Trash';
import Hr from '../../../components/molecules/hr/Hr';
import Check from '../../../components/atoms/icons/general/check/Check';
import AppContext from '../../../context/AppContext';
import { useWindowSize } from '@uidotdev/usehooks';

function Panier() {
  const { cart, removeFromCart } = useCart();
  const [detailsVisibility, setDetailsVisibility] = useState({});
  const [cartPrice, setCartPrice] = useState(0);
  const [tva, setTva] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const [totalReduction, setTotalReduction] = useState(0);
  const { setModalContent, setModalVisible } = useContext(AppContext);
  const { width } = useWindowSize();

  useEffect(() => {
    calculatePrices();
  }, [cart]);

  function calculatePrices() {
    const total = cart.reduce((total, item) => total + parseFloat(item.price.replace('€', '').replace(',', '.')), 0);
    const tvaRate = 0.2;
    const tva = total * tvaRate;
    const totalTTC = total + tva;

    const reduction = cart.reduce((totalReduction, item) => {
      const normalPrice = parseFloat(item.price.replace('€', '').replace(',', '.'));
      const discountPrice = item.discount ? parseFloat(item.discount.replace('€', '').replace(',', '.')) : normalPrice;
      return totalReduction + (normalPrice - discountPrice);
    }, 0);

    setCartPrice(total);
    setTva(tva);
    setTotalTTC(totalTTC);
    setTotalReduction(reduction);
  }

  function formatPrice(price) {
    return price.toFixed(2).replace('.', ',');
  }

  function toggleDetails(index) {
    setDetailsVisibility(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  }

  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            { name: 'Accueil', to: '/' },
            { name: 'Panier', to: '/panier' },
          ]}
        />
        <Banner
          title='Panier'
          subtitle="Validez ou modifiez le contenu de votre panier avant de commander et de recevoir votre rapport d'estimation"
        />
        <div className='panier-container'>
          {cart.length > 0 ? (
            <Fragment>
              <div className='panier-remplie-container'>
                <Card bgColor='var(--light-blue)' className='abonnement-card'>
                  <h2>
                    Mon Panier ({cart.length} offre{cart.length > 1 ? 's' : ''})
                  </h2>
                  <Hr />
                  <table className='cart-table'>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Nom de {width <= 576 ? <br /> : ''} l'offre</th>
                        <th>Inclus dans {width <= 576 ? <br /> : ''} votre offre</th>
                        <th className='quantite-offre-cart'>{width <= 576 ? 'Qté' : 'Quantité'}</th>
                        <th className='prix-ht-offre-cart'>Prix (HT)</th>
                      </tr>
                      <tr>
                        <td colSpan='5'>
                          <Hr />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((item, index) => (
                        <Fragment key={index}>
                          <tr key={`item-${index}`}>
                            <td>
                              <Trash onClick={() => removeFromCart(index)} />
                            </td>
                            <td className='selection-offre-cart'>{item.name}</td>
                            <td className='inclus-offre-cart'>
                              {width <= 576 ? (
                                <span
                                  className='mobile-details-link'
                                  onClick={() => {
                                    setModalContent({
                                      title: 'Détails de l\'offre "Maestro"',
                                      content: (
                                        <div className='details-content'>
                                          <ul>
                                            <li className='flex details-1-cart'>
                                              <Check width='15px' className='check-min-width' />
                                              Nombre illimité d'estimations avec rapport détaillé
                                            </li>
                                            <li className='flex details-1-cart'>
                                              <Check width='15px' className='check-min-width' />
                                              Accès aux ressources suivantes :
                                            </li>
                                            <ul className='ml-md dots-list details-2-cart'>
                                              <li>Outils pratiques à télécharger</li>
                                              <li>Conseils de pros</li>
                                              <li>Nos guides pour agents immos</li>
                                              <li>Plan de financement</li>
                                              <li>Simulateur de crédit</li>
                                              <li>Calculateur de cotisations</li>
                                            </ul>
                                          </ul>
                                        </div>
                                      ),
                                    });
                                    setModalVisible(true);
                                  }}>
                                  Détails
                                </span>
                              ) : (
                                ''
                              )}
                              <ul className={width <= 576 ? 'd-none' : ''}>
                                <li className='flex details-1-cart'>
                                  <Check width='15px' className='check-min-width' />
                                  Nombre illimité d'estimations avec rapport détaillé
                                </li>
                                <li className='flex details-1-cart'>
                                  <Check width='15px' className='check-min-width' />
                                  Accès aux ressources suivantes :
                                </li>
                                <ul
                                  className={`ml-md dots-list details-2-cart ${
                                    detailsVisibility[index] ? 'show' : ''
                                  }`}>
                                  <li>Outils pratiques à télécharger</li>
                                  <li>Conseils de pros</li>
                                  <li>Nos guides pour agents immos</li>
                                  <li>Plan de financement</li>
                                  <li>Simulateur de crédit</li>
                                  <li>Calculateur de cotisations</li>
                                </ul>
                                <span className='toggle-button' onClick={() => toggleDetails(index)}>
                                  {detailsVisibility[index] ? 'Voir moins' : 'Voir plus'}
                                </span>
                              </ul>
                            </td>
                            <td className='quantite-offre-cart'>1</td>
                            <td className='prix-ht-offre-cart'>
                              {formatPrice(parseFloat(item.price.replace('€', '').replace(',', '.')))}€
                            </td>
                          </tr>
                          {item.name === 'maestro' || item.name === 'optimum' ? (
                            <tr key={`engagement-${index}`}>
                              <td></td>
                              <td colSpan='4' className='engagement-abonnement-cart'>
                                Abonnement avec engagement de 12 mois
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td colSpan='5'>
                              <Hr />
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                  <div className='prix-details-section-cart'>
                    <div className='prix-details-cart'>
                      <div className='total-ht'>
                        <p>Total HT :</p>
                        <p>{formatPrice(cartPrice)}€</p>
                      </div>
                      <div className='total-tva'>
                        <p>Total TVA 20% :</p>
                        <p>{formatPrice(tva)}€</p>
                      </div>
                      {totalReduction > 0 && (
                        <div className='total-reduction'>
                          <p>Réduction</p>
                          <p>{`-${formatPrice(totalReduction)}€`}</p>
                        </div>
                      )}
                      <Hr />
                      <div className='total-ttc'>
                        <p>Total TTC :</p>
                        <p>{formatPrice(totalTTC)}€</p>
                      </div>
                    </div>
                  </div>
                  <div className='button-suivant-cart'>
                    <FilledButton padding='10px 20px' fontSize='1.2rem' to='/commande'>Suivant</FilledButton>
                  </div>
                </Card>
              </div>
            </Fragment>
          ) : (
            <Card bgColor='var(--light-blue)' className='panier-vide-card' padding={'60px'}>
              <h2>Votre panier est vide</h2>
              <p>
                Nous vous invitons à cliquer sur le bouton ci-dessous afin de consulter nos différents packs
                d'estimations.
              </p>
              <FilledButton to='/tarifs' className='redirection-tarif-button'>
                Consulter nos offres
              </FilledButton>
            </Card>
          )}
        </div>
      </section>
    </>
  );
}

export default Panier;