import './GuidelineStep.css';
import React from 'react';

function GuidelineStep({ alignRight, text, Icon, color }) {
  return (
    <div className={'guideline-step-container ' + (alignRight ? 'right' : '')} style={{ '--color': color }}>
      {alignRight ? (
        <p>{text}</p>
      ) : (
        <div className='zigzag-container'>
          <div className='zigzag-1' />
          <div className='zigzag-2' />
          <div className='zigzag-mask' />
        </div>
      )}
      <Icon />
      {alignRight ? (
        <div className='zigzag-container'>
          <div className='zigzag-1' />
          <div className='zigzag-2' />
          <div className='zigzag-mask' />
        </div>
      ) : (
        <p>{text}</p>
      )}
    </div>
  );
}

export default GuidelineStep;
