import React, { useRef, useEffect } from 'react';
import { toggleCollapseElement } from '../../../../utils/Utils';
import './Checkbox.css';
import Warning from '../../../atoms/icons/general/warning/Warning';
import Check from '../../../atoms/icons/general/check/Check';

function Checkbox({
  label,
  name,
  register,
  validation = '',
  error,
  visible,
  onChange,
  onFocus,
  onKeyDown,
  className = '',
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseElement(ref.current, visible);
  }, [visible]);

  const inputRegister = register ? register(name, validation) : null;

  return (
    <div className={`checkbox-error-container ${className}`} ref={ref}>
      <div className='field-container checkbox-container'>
        <input
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          disabled={visible !== undefined && !visible}
          id={name}
          name={name}
          type='checkbox'
          {...inputRegister}
          onChange={e => {
            register && inputRegister.onChange(e);
            onChange && onChange(e);
          }}
        />
        <label htmlFor={name} className='checkbox-check'>
          <Check width='10px' color='var(--white)' />
        </label>
        <label className='form-label' htmlFor={name}>
          {label}
        </label>
      </div>
      {error && (
        <div className='form-error'>
          <Warning /> <p>{error}</p>
        </div>
      )}
    </div>
  );
}

export default Checkbox;
