import './ThumbnailsGroup.css';
import Thumbnail from '../../molecules/thumbnail/Thumbnail';
import React, { Fragment, useEffect, useState } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';

// data = [
//     {
//         title: '',
//         alt: '',
//         tag: '',
//         picture: '',
//         link: '',
//         linkText: '',
//     },
// ];

function ThumbnailsGroup({ data, className = '', margin = '0', hasFeaturedArticle = false }) {
  const [emptyThumbnails, setEmptyThumbnails] = useState(<></>);
  const { width, _ } = useWindowSize();

  useEffect(() => {
    let itemsPerRow;
    let rows = [];

    switch (true) {
      case width >= 576 && width < 992:
        itemsPerRow = 2;
        break;
      case width >= 992 && width < 1200:
        itemsPerRow = 3;
        break;
      case width >= 1200:
        itemsPerRow = 4;
        break;
    }

    let dataLength = data.length;

    if(hasFeaturedArticle) {
      dataLength++;
    }

    if (dataLength % itemsPerRow)
      for (let i = 0; i < itemsPerRow - (dataLength % itemsPerRow) ; i++) {
        rows.push(<div key={i} className='thumbnail-group-unit'></div>);
      }

    setEmptyThumbnails(<>{rows.map(row => row)}</>);
  }, [width, data]);

  return (
    <section className={'thumbnails-group-container ' + className} style={{ '--margin': margin }}>
      {hasFeaturedArticle && <div className='d-none'></div>}
      {data.map((thumbnail, index) => {
        if (hasFeaturedArticle && index === 0) {
          return (
            <Fragment key={index}>
              <div className='thumbnail-group-unit featured-article'>
                <Thumbnail
                  link={thumbnail.article_url}
                  title={thumbnail.title}
                  picture={thumbnail.picture}
                  tag={thumbnail.tag_name}
                  alt={thumbnail.alt}
                  linkText={thumbnail.linkText}
                />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment key={index}>
              <div className='thumbnail-group-unit'>
                <Thumbnail
                  link={thumbnail.article_url}
                  title={thumbnail.title}
                  picture={thumbnail.picture}
                  tag={thumbnail.tag_name}
                  alt={thumbnail.alt}
                  linkText={thumbnail.linkText}
                />
              </div>
            </Fragment>
          );
        }
      })}
      {emptyThumbnails}
    </section>
  );  
}

export default ThumbnailsGroup;