import { useWindowScroll } from '@uidotdev/usehooks';
import Searchbar from '../searchbar/Searchbar';
import './StickySearchbar.css';
import React, { useEffect, useState } from 'react';
import FilledButton from '../buttons/filledButton/FilledButton';

function StickySearchbar({
  onChange = () => {},
  placeholder = '',
  bgColor = 'var(--beige)',
  margin = '0 0 10px',
  setValue = () => {},
  resetValueTrigger = false,
  showFilterButton = false,
}) {
  const [searchbarTop, setSearchbarTop] = useState(-70);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  const [windowScroll] = useWindowScroll();

  useEffect(() => {
    handleScrollChange();
  }, [windowScroll]);

  function handleScrollChange() {
    if (Math.abs(windowScroll.y - lastScrollPosition) < 15) return;

    setSearchbarTop(windowScroll.y < lastScrollPosition ? '0' : '-70px');
    setLastScrollPosition(windowScroll.y);
  }

  return (
    <div className='sticky-searchbar-container' style={{ '--top': searchbarTop, '--margin': margin }}>
      <Searchbar
        resetValueTrigger={resetValueTrigger}
        topTreshold={20}
        onChange={onChange}
        placeholder={placeholder}
        bgColor={bgColor}
      />
      {showFilterButton && <FilledButton padding='10px 15px' className='filtre-article-button'>Filtre par catégorie</FilledButton>}
    </div>
  );
}

export default StickySearchbar;
