import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { max2500Validation, numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import Info from '../../../../atoms/icons/general/info/Info.js';
import { ParcoursContext } from '../../../../../context/ParcoursContext.js';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput.js';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { removeSpaces } from '../../../../../utils/Utils.js';

function BilanComptable({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const Form = { watch, register, setValue };
  const {
    showTooltip,
    typesCommerce,
    moveToNextStep,
    getStepToGo,
    setGoToStep,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    fillInputsFromEstimationValues,
    estimationValues,
    parcoursType,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'bilan_comptable',
      ['immobilisations_financieres', 'actif_circulant', 'comptes_courants_associes', 'total_dettes'],
      setValue,
      estimationValues
    );
    setValue('valeur_materiel_exploitation', estimationValues?.materiel_exploitation?.valeur_materiel_exploitation);
    fillInputsFromEstimationValues(
      'ca',
      ['ca_ht', 'ca_hebergement_ht', 'ca_restauration_divers_ht', 'total_commissions_nettes'],
      setValue,
      estimationValues
    );
    fillInputsFromEstimationValues(
      'charges',
      ['achat_marchandise', 'total_charges_externes'],
      setValue,
      estimationValues
    );
    if (!secondaryParcours) setValue('note_step_4', estimationValues?.notes?.note_step_4);
  }, [estimationValues]);

  async function formSubmit(values) {
    const tmp = {
      bilan_comptable: {
        immobilisations_financieres: removeSpaces(values.immobilisations_financieres, 1),
        actif_circulant: removeSpaces(values.actif_circulant, 1),
        comptes_courants_associes: removeSpaces(values.comptes_courants_associes, 1),
        total_dettes: removeSpaces(values.total_dettes, 1),
      },
      materiel_exploitation: {
        valeur_materiel_exploitation: removeSpaces(values.valeur_materiel_exploitation, 1),
      },
      ca: {
        ca_ht: removeSpaces(values.ca_ht, 1),
        ca_hebergement_ht: removeSpaces(values.ca_hebergement_ht, 1),
        ca_restauration_divers_ht: removeSpaces(values.ca_restauration_divers_ht, 1),
        total_commissions_nettes: removeSpaces(values.total_commissions_nettes, 1),
      },
      charges: {
        achat_marchandise: removeSpaces(values.achat_marchandise, 1),
        total_charges_externes: removeSpaces(values.total_charges_externes, 1),
      },
      notes: {
        note_step_4: values.note_step_4,
      },
    };
    if (!secondaryParcours) tmp.notes = { note_step_4: values.note_step_4 };

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      {parcoursType === 2 ? ( // tds only
        <>
          <fieldset className='borderless-fieldset'>
            <h2>bilan comptable</h2>
            <NumberInput // visible tds
              useForm={Form}
              label={
                <>
                  <div>
                    immobilisations financières <span className='text-sky-blue'>(du dernier bilan connu)</span>
                  </div>
                  <Info onClick={() => showTooltip('Parcours_Titres_1_immo_financieres.webp')} />
                </>
              }
              name='immobilisations_financieres'
              error={errors?.immobilisations_financieres && errors.immobilisations_financieres.message}
              validation={numberValidation}
              icon='euro'
            />
            <NumberInput // visible tds
              useForm={Form}
              label={
                <>
                  <div>
                    actif circulant <span className='text-sky-blue'>(du dernier bilan connu)</span>
                  </div>
                  <Info onClick={() => showTooltip('ParcoursTitres-2Actifcirculant.webp')} />
                </>
              }
              name='actif_circulant'
              error={errors?.actif_circulant && errors.actif_circulant.message}
              validation={numberValidation}
              icon='euro'
            />
            <NumberInput // visible tds
              useForm={Form}
              label={
                <>
                  <div>
                    comptes courants d'associés <span className='text-sky-blue'>(du dernier bilan connu)</span>
                  </div>
                  <Info onClick={() => showTooltip('ParcoursTitres_4_Comptes_courants.webp')} />
                </>
              }
              name='comptes_courants_associes'
              error={errors?.comptes_courants_associes && errors.comptes_courants_associes.message}
              validation={numberValidation}
              icon='euro'
            />
            <NumberInput // visible tds
              useForm={Form}
              label={
                <>
                  <div>
                    total des dettes (dont comptes courants d'associés){' '}
                    <span className='text-sky-blue'>(du dernier bilan connu)</span>
                  </div>
                  <Info onClick={() => showTooltip('ParcoursTitres_3_Dettes.webp')} />
                </>
              }
              name='total_dettes'
              error={errors?.total_dettes && errors.total_dettes.message}
              validation={numberValidation}
              icon='euro'
            />
          </fieldset>
          <fieldset className='borderless-fieldset'>
            <h2>matériel d'exploitation</h2>
            <NumberInput // visible tds
              useForm={Form}
              label={
                <>
                  <div>
                    valeur de marché <span className='text-sky-blue'>(du dernier bilan connu)</span>
                  </div>
                  <Info onClick={() => showTooltip('TutoMaterielExploitation.webp')} />
                </>
              }
              name='valeur_materiel_exploitation'
              error={errors?.valeur_materiel_exploitation && errors.valeur_materiel_exploitation.message}
              validation={numberValidation}
              icon='euro'
            />
          </fieldset>
        </>
      ) : null}

      <fieldset className='borderless-fieldset'>
        <h2>chiffre d’affaires hors taxes</h2>
        <NumberInput
          visible={!typesCommerce.includes(1)}
          useForm={Form}
          label={
            typesCommerce.includes(5) ? (
              <>
                chiffre d’affaires (hors commissions)
                <Info onClick={() => showTooltip('1_4_1_CA_hors_Commissions.webp')} />
              </>
            ) : (
              <>
                chiffre d’affaires
                <Info onClick={() => showTooltip('1_CA_retenir.webp')} />
              </>
            )
          }
          name='ca_ht'
          error={errors?.ca_ht && errors.ca_ht.message}
          validation={numberValidation}
          icon='euro'
        />
        <NumberInput
          useForm={Form}
          visible={typesCommerce.includes(1)}
          label={
            typesCommerce.includes(1) ? (
              <>
                CA Hébergement / Matelas
                <Info onClick={() => showTooltip('12CA_hebergement_plages_CHR.webp')} />
              </>
            ) : (
              <>
                ca hébergement
                <Info onClick={() => showTooltip('12CA_hebergement_plages_CHR.webp')} />
              </>
            )
          }
          name='ca_hebergement_ht'
          error={errors?.ca_hebergement_ht && errors.ca_hebergement_ht.message}
          validation={numberValidation}
          icon='euro'
        />
        <NumberInput
          useForm={Form}
          visible={typesCommerce.includes(1)}
          label={
            <>
              ca restauration et divers
              <Info onClick={() => showTooltip('13_CA_rest_bar_divers_CHR.webp')} />
            </>
          }
          name='ca_restauration_divers_ht'
          error={errors?.ca_restauration_divers_ht && errors.ca_restauration_divers_ht.message}
          validation={numberValidation}
          icon='euro'
        />
        <NumberInput
          useForm={Form}
          visible={typesCommerce.includes(1) || typesCommerce.includes(5)}
          label={
            <>
              total des commissions nettes
              <Info onClick={() => showTooltip('14bis_Commissions_nettes_Auberge_Hotel_Plage_uniquement.webp')} />
            </>
          }
          name='total_commissions_nettes'
          register={register}
          error={errors?.total_commissions_nettes && errors.total_commissions_nettes.message}
          type='number'
          validation={numberValidation}
          icon='euro'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>coût matières et charges externes</h2>
        <NumberInput
          useForm={Form}
          label={
            <>
              coût d’achat marchandises et matières premières
              <Info onClick={() => showTooltip('2_Achats_marchandises_a_totaliser.webp')} />
            </>
          }
          name='achat_marchandise'
          error={errors?.achat_marchandise && errors.achat_marchandise.message}
          validation={numberValidation}
          icon='euro'
        />
        <NumberInput
          label={
            <>
              total des autres achats et charges externes
              <Info onClick={() => showTooltip('3_Charges_externes.webp')} />
            </>
          }
          useForm={Form}
          name='total_charges_externes'
          error={errors?.total_charges_externes && errors.total_charges_externes.message}
          validation={numberValidation}
          icon='euro'
          onFocus={() => {
            if (step == index + 1 && secondaryParcours) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && secondaryParcours) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        {!secondaryParcours && (
          <Textarea
            error={errors?.note_step_4 && errors.note_step_4.message}
            validation={max2500Validation}
            label='notes et remarques'
            className='mt-md'
            register={register}
            name='note_step_4'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        )}
      </fieldset>
    </form>
  );
}

export default BilanComptable;
