import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import { numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import Info from '../../../../atoms/icons/general/info/Info';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { removeSpaces } from '../../../../../utils/Utils.js';

function LoyerCharges({ index }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const Form = { register, setValue, watch };

  const {
    showTooltip,
    step,
    moveToNextStep,
    setStep,
    submitFormsMc,
    postValues,
    setGoToStep,
    getStepToGo,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'loyer_charges',
      ['charges_locatives_annuelles', 'loyer_annuel', 'taxe_fonciere'],
      setValue,
      estimationValues
    );
    setValue('note_step_6', estimationValues?.notes?.note_step_6);
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      loyer_charges: {
        loyer_annuel: removeSpaces(values.loyer_annuel),
        charges_locatives_annuelles: removeSpaces(values.charges_locatives_annuelles),
        taxe_fonciere: removeSpaces(values.taxe_fonciere),
      },
      notes: {
        note_step_6: values.note_step_6,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }
  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
        <fieldset className='borderless-fieldset'>
          <h2>loyer & charges</h2>
          <div className='row-1000 mb-sm'>
            <NumberInput
              useForm={Form}
              icon='euro'
              label={
                <>
                  <div>
                    montant du loyer&nbsp; <span className='bold-label'>annuelles</span> &nbsp;(hors charges, hors taxe
                    foncière)
                  </div>
                  <Info onClick={() => showTooltip('3_Loyer_annuel.webp')} />
                </>
              }
              name='loyer_annuel'
              error={errors?.loyer_annuel && errors.loyer_annuel.message}
              validation={numberValidation}
            />
            <NumberInput
              useForm={Form}
              icon='euro'
              label={
                <>
                  <div>
                    montant des dernières charges locatives&nbsp; <span className='bold-label'>annuelles</span>
                  </div>
                  <Info onClick={() => showTooltip('4_Charges_locatives_annuelles.webp')} />
                </>
              }
              name='charges_locatives_annuelles'
              error={errors?.charges_locatives_annuelles && errors.charges_locatives_annuelles.message}
              validation={numberValidation}
            />
          </div>
          <NumberInput
            useForm={Form}
            icon='euro'
            label={
              <>
                montant de la dernière taxe foncière
                <Info onClick={() => showTooltip('5_Taxe_fonciere.webp')} />
              </>
            }
            name='taxe_fonciere'
            error={errors?.taxe_fonciere && errors.taxe_fonciere.message}
            validation={numberValidation}
          />
          <Textarea
            register={register}
            name='note_step_6'
            className='mt-md'
            label='notes et remarques'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        </fieldset>
      </form>
    </>
  );
}

export default LoyerCharges;
