import React from 'react';
import { ReactComponent as FullLogo } from '../../../../assets/logos/logo-emc-full-1.svg';
import { ReactComponent as IconLogo } from '../../../../assets/logos/logo-emc-icon.svg';

//typeLogo = 1 : full / 2 : icon
function EmcLogo({ dark, className, typeLogo = 1, height = '85px' }) {
  return typeLogo === 1 ? (
    <FullLogo style={{ fill: dark ? 'var(--dark-blue)' : 'var(--white)', height: height }} className={className} />
  ) : (
    <IconLogo style={{ fill: dark ? 'var(--dark-blue)' : 'var(--white)', height: height }} className={className} />
  );
}

export default EmcLogo;
