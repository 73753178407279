import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import Select from '../../../../molecules/formComponents/select/Select';
import Info from '../../../../atoms/icons/general/info/Info';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { fetchDropdownValuesApi } from '../../../../../api/ParcoursApi.js';
import { getSelectValue } from '../../../../../utils/Utils.js';
import { useWindowSize } from '@uidotdev/usehooks';
import ZoneInfluence from '../../../../atoms/parcours/zoneInfluence/ZoneInfluence.js';

function SituationGeographique({ index }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [dropdownsValues, setDropdownsValues] = useState({});
  const {
    showTooltip,
    step,
    setStep,
    moveToNextStep,
    submitFormsMc,
    postValues,
    getStepToGo,
    setGoToStep,
    estimationValues,
    fillSelectsFromEstimationValues,
  } = useContext(ParcoursContext);
  const { width } = useWindowSize();

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
    fillDropdownsValues();
  }, [step, estimationValues]);

  useEffect(() => {
    fillSelectsFromEstimationValues(
      'situation_geographique',
      ['qualite_secteur', 'qualite_implantation_rue', 'zone_influence'],
      setValue,
      estimationValues
    );
    setValue('note_step_2', estimationValues?.notes?.note_step_2);
  }, [estimationValues, dropdownsValues]);

  async function formSubmit(values) {
    postValues({
      situation_geographique: {
        qualite_secteur: { id: getSelectValue(values.qualite_secteur) },
        qualite_implantation_rue: { id: getSelectValue(values.qualite_implantation_rue) },
        zone_influence: { id: values.zone_influence },
      },
      notes: {
        note_step_2: values.note_step_2,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    const values = {};
    try {
      values.qualite_secteur = (await fetchDropdownValuesApi('qualite_secteur')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.qualite_implantation_rue = (await fetchDropdownValuesApi('qualite_implantation_rue')).data;
    } catch (error) {
      console.error(error);
    }
    setDropdownsValues(values);
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>situation géographique</h2>
        <Radio
          padding='2rem'
          label={
            <>
              zone d’influence de l’exploitation occupant (ou ayant occupé) le local{' '}
              <Info onClick={() => showTooltip('1Rayonnement_agglomeration.webp')} />{' '}
            </>
          }
          register={register}
          name='zone_influence'
          className='zone-influence-radio'
          error={errors?.zone_influence && errors.zone_influence.message}
          
          content={[
            {
              label: (
                <>
                  {width > 1200 && <br />}
                  Rayonnement local
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />}
                  Très petite ville et village
                  <br /> (-5.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xs' className='parcours-zone-influence' />
                </>
              ),
              value: 1,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  multicommunal
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Petite ville {width > 1200 && <br />}
                  (5.000 à 20.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='sm' className='parcours-zone-influence' />
                </>
              ),
              value: 2,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  départemental
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Ville moyenne {width > 1200 && <br />}
                  (20.000 à 100.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='lg' className='parcours-zone-influence' />
                </>
              ),
              value: 3,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />} régional&nbsp;ou&nbsp;+
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Grande ville {width > 1200 && <br />}(+100.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xl' className='parcours-zone-influence' />
                </>
              ),
              value: 4,
            },
          ]}
        />
        <Select
          register={register}
          error={errors?.qualite_secteur && errors.qualite_secteur.message}
          name='qualite_secteur'
          label={
            <>
              qualité de secteur et de clientèle dans l’agglomération{' '}
              <Info onClick={() => showTooltip('2_Qualite_de_secteur.webp')} />
            </>
          }>
          {dropdownsValues.qualite_secteur?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <Select
          register={register}
          error={errors?.qualite_implantation_rue && errors.qualite_implantation_rue.message}
          name='qualite_implantation_rue'
          label={
            <>
              qualité d’implantation dans la rue
              <Info onClick={() => showTooltip('2bis_Implantation_rue.webp')} />
            </>
          }>
          {dropdownsValues.qualite_implantation_rue?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}{' '}
        </Select>
        <Textarea
          register={register}
          name='note_step_2'
          label='notes et remarques'
          className='mt-md'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default SituationGeographique;
