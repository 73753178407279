import React, { useContext, useState } from 'react';
import Input from '../../../molecules/formComponents/input/Input';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';
import { emailValidation, passwordValidation } from '../../../../utils/formValidation/FormValidation';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import './ConnexionForm.css';
import { loginUserApi } from '../../../../api/CompteApi';
import AppContext from '../../../../context/AppContext';
import ForgotPasswordModal from '../../modals/compte/forgotPasswordModal/ForgotPasswordModal';
import PasswordInput from '../../../molecules/formComponents/passwordInput/PasswordInput';
import ConfirmInscriptionModal from '../../modals/compte/confirmInscriptionModal/ConfirmInscriptionModal';

function ConnexionForm() {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const { createNotification, setUserId, setModalContent, setModalVisible } = useContext(AppContext);
  const [formError, setFormError] = useState(<></>);
  const navigate = useNavigate();

  async function onSubmit(values) {
    try {
      const res = await loginUserApi({
        email: values.email,
        password: values.password,
      });
      setUserId(res.data.uuid);
      setFormError(<></>);
      navigate('/mon-compte/mes-estimations');
    } catch (error) {
      console.log(error);
      let notificationMessage = <></>;

      switch (error.response.status) {
        case 401:
          notificationMessage = <>Votre email ou votre mot de passe est incorrect.</>;
          break;
        case 403:
          switch (error.response.data.infos.error_code) {
            case '01':
              localStorage.setItem('temporary_uuid', error.response.data.infos.uuid);
              showConfirmInscriptionModal();
              return;
            case '02':
              showDesactivedAccountModal();
              return;
          }
        case 500:
        default:
          notificationMessage = <>Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.</>;
      }

      setFormError(notificationMessage);
      createNotification(notificationMessage, 'var(--red)', 'var(--dark-blue');
    }
  }

  function showDesactivedAccountModal() {
    setModalContent({
      title: 'Compte désactivé',
      content: (
        <>
          Votre compte est désactivé. Veuillez contact le support à l'adresse suivante :{' '}
          <a href='mailto:contact@estimermoncommerce.fr' className='link'>
            contact@estimermoncommerce.fr
          </a>
        </>
      ),
    });

    setModalVisible(true);
  }

  function showConfirmInscriptionModal() {
    setModalContent({
      title: 'Confirmer votre inscription',
      content: (
        <>
          <p className='centered'>Votre compte n'est pas encore activé</p>
          <ConfirmInscriptionModal email={getValues('email')} password={getValues('password')} navigate={navigate} />
        </>
      ),
    });
    setModalVisible(true);
  }

  function showForgotPasswordModal() {
    setModalContent({ title: 'Mot de passe oublié', content: <ForgotPasswordModal email={getValues('email')} /> });
    setModalVisible(true);
    setFormError(<></>);
    setValue('email', '');
    setError('email', { type: 'manual', message: '' });
    setValue('password', '');
    setError('password', { type: 'manual', message: '' });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className='borderless-fieldset'>
        <h2>Se connecter</h2>
        <Input
          type='email'
          register={register}
          error={errors?.email && errors.email.message}
          validation={emailValidation}
          name='email'
          label='adresse mail'
        />
        <PasswordInput
          type='password'
          register={register}
          error={errors?.password && errors.password.message}
          validation={passwordValidation}
          name='password'
          label='mot de passe'
        />
        <div className='form-error-container'>{formError}</div>
        <div className='centered mt-sm'>
          <FilledButton type='submit' padding='10px 25px'>
            Se connecter
          </FilledButton>
        </div>
        <Link className='forgot-password' onClick={showForgotPasswordModal}>
          Mot de passe oublié ?
        </Link>
      </fieldset>
    </form>
  );
}

export default ConnexionForm;
