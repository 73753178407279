import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../../../molecules/formComponents/input/Input';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';

function BienAEvaluer({ id, index }) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    step,
    setStep,
    moveToNextStep,
    submitFormsMc,
    postValues,
    getStepToGo,
    setGoToStep,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues('infos', ['nom', 'rue', 'ville', 'code_postal'], setValue, estimationValues);
    setValue('note_step_1', estimationValues?.notes?.note_step_1);
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      infos: {
        nom: values.nom,
        rue: values.rue,
        ville: values.ville,
        code_postal: values.code_postal,
      },
      notes: {
        note_step_1: values.note_step_1,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>le bien à évaluer</h2>
        <Input
          setValue={setValue}
          label='intitulé de votre bien'
          name='nom'
          register={register}
          error={errors?.nom && errors.nom.message}
        />
        <Input
          setValue={setValue}
          label='n°/nom de rue'
          name='rue'
          register={register}
          error={errors?.rue && errors.rue.message}
        />
        <div className='row-1000 mb-sm'>
          <Input
            setValue={setValue}
            label='ville'
            name='ville'
            register={register}
            error={errors?.ville && errors.ville.message}
          />
          <Input
            setValue={setValue}
            label='code postal'
            name='code_postal'
            register={register}
            error={errors?.code_postal && errors.code_postal.message}
          />
        </div>
        <div className='centered mt-3'>
          <p className='text-sky-blue poppins-bold'>Enregistrez jusqu'à trois images à ajouter sur votre rapport</p>
          <p>(la première apparaîtra sur la page de garde, les suivantes sur les pages annexes)</p>
        </div>
        <Input
          setValue={setValue}
          label='image 1'
          validation={''}
          name='imageUpload1'
          type='file'
          register={register}
          error={errors?.imageUpload1 && errors.imageUpload1.message}
        />
        <Input
          setValue={setValue}
          label='image 1'
          validation={''}
          name='imageUpload2'
          type='file'
          register={register}
          error={errors?.imageUpload2 && errors.imageUpload2.message}
        />
        <Input
          setValue={setValue}
          label='image 1'
          validation={''}
          name='imageUpload3'
          type='file'
          register={register}
          error={errors?.imageUpload3 && errors.imageUpload3.message}
        />
        <Textarea
          register={register}
          name='note_step_1'
          label='notes et remarques'
          className='mt-md'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default BienAEvaluer;
