import './VisitingCard.css';
import React from 'react';
import Phone from '../../atoms/icons/general/phone/Phone';
import Email from '../../atoms/icons/general/email/Email';
import Location from '../../atoms/icons/general/location/Location';

function VisitingCard({
  avatar = '',
  firstname = '',
  position = '',
  slogan = '',
  mobilePhone = '',
  fixedPhone = '',
  email = '',
  address1 = '',
  address2 = '',
  zipCode = '',
  city = '',
  company = '',
  website = '',
  rsac = '',
  siret = '',
  logo = '',
  lastname = '',
  id = '',
}) {
  return (
    <div className='visiting-card-container'>
      <div className='card-top'>
        <img
          src={avatar}
          alt='Avatar'
          id={'avatar-img-' + id}
          className={'card-img ' + (avatar === '' ? 'd-none' : '')}
        />
        <div>
          <p className='card-name'>
            <span>{firstname}</span>&nbsp;
            <span>{lastname}</span>
          </p>
          <p className='card-position'>{position}</p>
          <p className='card-slogan'>{slogan}</p>
        </div>
      </div>
      <div className='card-mid'>
        {(mobilePhone !== '' || fixedPhone !== '') && (
          <div className='card-mid-content'>
            <Phone />
            <p>
              <span>{mobilePhone}</span>
              {mobilePhone !== '' && fixedPhone !== '' && ' - '}
              <span>{fixedPhone}</span>
            </p>
          </div>
        )}
        {email !== '' && (
          <div className='card-mid-content'>
            <Email />
            <p>{email}</p>
          </div>
        )}
        {(address1 !== '' || address2 !== '' || zipCode !== '' || city !== '') && (
          <div className='card-mid-content'>
            <Location />
            <div>
              <p>{address1}</p>
              <p>{address2}</p>
              <p>
                {zipCode !== '' && <span>{zipCode}&nbsp;</span>}
                <span>{city}</span>
              </p>
            </div>
          </div>
        )}
      </div>
      <div className='card-bottom'>
        <div>
          <p className='card-company'>{company}</p>
          <p>{website}</p>
          <p>
            {rsac !== '' && siret !== '' && 'RSAC : ' + rsac}
            {rsac !== '' && siret === '' && 'RSAC : ' + rsac}
            {rsac === '' && siret !== '' && 'Siret : ' + siret}
          </p>
        </div>
        <img src={logo} alt='Logo' id={'logo-img-' + id} className={'card-img ' + (logo === '' ? 'd-none' : '')} />
      </div>
    </div>
  );
}

export default VisitingCard;
