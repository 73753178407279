import './InfiniteThumbnailsRow.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Thumbnail from '../../molecules/thumbnail/Thumbnail';
import { useWindowSize } from '@uidotdev/usehooks';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import AppContext from '../../../context/AppContext';

// data = [
//     {
//         title: '',
//         tag: '',
//         picture: '',
//         link: '',
//         linkText: '',
//     },
// ];

function InfiniteThumbnailsRow({ data, margin = '30px auto', className = '', title, thumbnailWidth = 'auto' }) {
  const [fingerStartPosition, setFingerStartPosition] = useState({
    x: 0,
    y: 0,
  });
  const [slidePosition, _setSlidePosition] = useState(0);
  const [scrollHorizontal, setScrollHorizontal] = useState(0);
  const [lastSlideX, setLastSlideX] = useState(0);
  const [sliderDimensions, setSliderDimensions] = useState({
    width: 0,
    childrenCount: 0,
    childWidth: 0,
  });
  const [thumbnails, setThumbnails] = useState([]);
  const [isScrolling, setIsScrolling] = useState(false);
  const [canScroll, setCanScroll] = useState(true);
  const [displayedItemsCount, setDisplayedItemsCount] = useState(1);
  const [thumbnailPadding, setThumbnailPadding] = useState(20);
  const [sliderContainerWidth, setSliderContainerWidth] = useState(0);
  const [thumbnailsCopiesCount, setThumbnailsCopiesCount] = useState(1);
  const [loadedPage, setLoadedPage] = useState(false);

  const { width, _ } = useWindowSize();
  const sliderRef = useRef(null);
  const containerRef = useRef(null);
  const { toggleBodyScroll } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      setLoadedPage(true);
    }, 500);
  }, []);

  useEffect(() => {
    setBoundsThumbnails();
  }, [data, displayedItemsCount]);

  useEffect(() => {
    setSliderMeasures();
  }, [width, thumbnails, thumbnailPadding]);

  useEffect(() => {
    let tmp = 0;
    if (width > 576) tmp = 40;
    if (width <= 576) tmp = 20;

    if (tmp != thumbnailPadding) setThumbnailPadding(tmp);
  }, [width]);

  useEffect(() => {
    teleportSliderInBounds();
  }, [slidePosition]);

  function goToIndex(index, withTransition = true) {
    if (!sliderRef.current) return;

    if (!withTransition) sliderRef.current.style.transition = 'none';
    setSlidePosition(index * getSliderDimensions().childWidth);
    setTimeout(() => {
      if (sliderRef.current) sliderRef.current.style.transition = 'right 0.3s';
    }, 300);
  }

  function setSliderMeasures() {
    setTimeout(() => {
      if (sliderRef.current) setSliderDimensions(getSliderDimensions);

      if (containerRef.current) setSliderContainerWidth(containerRef.current.offsetWidth);

      let tmp = 1;
      switch (true) {
        case width > 576 && width <= 768:
          tmp = 2;
          break;
        case width > 768 && width <= 1200:
          tmp = 3;
          break;
        case width > 1200:
          tmp = 4;
      }
      setDisplayedItemsCount(tmp);
    }, 100);
  }

  function getSliderDimensions() {
    if (sliderRef.current) {
      return {
        width: sliderRef.current.offsetWidth,
        childrenCount: sliderRef.current.children.length,
        childWidth: sliderRef.current.children[0] && sliderRef.current.children[0].offsetWidth + thumbnailPadding,
        padding: parseInt(window.getComputedStyle(sliderRef.current).getPropertyValue('padding-left')) * 2,
      };
    }
  }

  function setBoundsThumbnails() {
    const tmp = [];
    const thumbnailsCopiesCount = displayedItemsCount * 2 - 1;

    for (const element of data) tmp.push(element);

    if (tmp.length > displayedItemsCount) {
      for (let i = 0; i < thumbnailsCopiesCount; i++) {
        tmp.push(tmp[i]);
      }
      for (let i = 0; i < thumbnailsCopiesCount; i++) {
        tmp.unshift(tmp[data.length - 1]);
      }
    }

    setThumbnails(tmp);
    setThumbnailsCopiesCount(thumbnailsCopiesCount);
    setTimeout(() => {
      goToIndex(thumbnailsCopiesCount, false);
    }, 200);
  }

  function teleportSliderInBounds() {
    const index = slidePosition / getSliderDimensions().childWidth;
    if (thumbnails.length >= displayedItemsCount && loadedPage) {
      if (index + displayedItemsCount <= thumbnailsCopiesCount) {
        setTimeout(() => {
          if (sliderRef.current) sliderRef.current.style.transition = 'none';
          goToIndex(index + data.length);

          setTimeout(() => {
            if (sliderRef.current) sliderRef.current.style.transition = 'right 0.3s';
          }, 10);
        }, 300);
      } else if (index >= data.length + thumbnailsCopiesCount)
        setTimeout(() => {
          if (sliderRef.current) sliderRef.current.style.transition = 'none';
          goToIndex(index - data.length);

          setTimeout(() => {
            if (sliderRef.current) sliderRef.current.style.transition = 'right 0.3s';
          }, 10);
        }, 300);
    }
  }

  function setSlidePosition(value) {
    const sliderDimensions = getSliderDimensions();

    if (displayedItemsCount === sliderRef.current.children.length) return;

    if (
      value + 5 >= -sliderDimensions.padding / 2 &&
      value - 5 <=
        sliderDimensions.width - sliderDimensions.padding / 2 - sliderDimensions.childWidth * displayedItemsCount
    ) {
      _setSlidePosition(value);
    }
  }

  function handleScroll(e) {
    if (!canScroll) return;

    const fingerPosition =
      e.type === 'mousemove'
        ? {
            x: e.pageX,
            y: e.pageY,
          }
        : {
            x: e.touches[0].screenX,
            y: e.touches[0].screenY,
          };
    const slideX = fingerStartPosition.x - fingerPosition.x;
    const slideY = fingerStartPosition.y - fingerPosition.y;

    switch (scrollHorizontal) {
      case 0:
        if (Math.abs(slideX) > Math.abs(slideY)) {
          setScrollHorizontal(1);
          toggleBodyScroll(false);
        } else setScrollHorizontal(-1);
        break;
      case -1:
        break;
      case 1:
        setScrollHorizontal(1);
        setSlidePosition(slidePosition + lastSlideX - fingerPosition.x);
        setLastSlideX(fingerPosition.x);
    }
  }

  function handleScrollStart(e) {
    if (!canScroll) return;

    sliderRef.current.style.transition = 'none';

    if (e.type === 'mousedown') {
      setFingerStartPosition({ x: e.pageX, y: e.pageY });
      setLastSlideX(e.pageX);
    } else {
      setFingerStartPosition({ x: e.touches[0].screenX, y: e.touches[0].screenY });
      setLastSlideX(e.touches[0].screenX);
    }

    setIsScrolling(true);
  }

  function handleScrollEnd() {
    sliderRef.current.style.transition = 'right 0.3s';

    toggleBodyScroll(true);
    setScrollHorizontal(0);
    setSlidePosition(getClosestArticle(fingerStartPosition.x > lastSlideX));

    setCanScroll(false);
    setIsScrolling(false);
    setTimeout(() => {
      setCanScroll(true);
    }, 200);
  }

  function getClosestArticle(upper) {
    let value;
    const maxValue = sliderDimensions.childWidth * (sliderDimensions.childrenCount - displayedItemsCount);

    if (upper) value = Math.ceil(slidePosition / sliderDimensions.childWidth) * sliderDimensions.childWidth;
    else value = Math.floor(slidePosition / sliderDimensions.childWidth) * sliderDimensions.childWidth;

    if (value > maxValue) value = maxValue;

    if (value < 0) value = 0;

    return value;
  }

  function toNextArticle(upper) {
    let value = 0;
    const maxValue = sliderDimensions.childWidth * (sliderDimensions.childrenCount - displayedItemsCount);

    if (upper)
      value = Math.min(
        maxValue,
        Math.ceil((slidePosition + sliderDimensions.childWidth * displayedItemsCount) / sliderDimensions.childWidth) *
          sliderDimensions.childWidth
      );
    else
      value = Math.max(
        0,
        Math.floor((slidePosition - sliderDimensions.childWidth * displayedItemsCount) / sliderDimensions.childWidth) *
          sliderDimensions.childWidth
      );
    setSlidePosition(value);
  }

  return (
    <section
      className={
        'thumbnails-row-container ' + className + (width > 992 ? ' container' : ' ') + (loadedPage ? '' : ' hidden')
      }
      style={{
        '--margin': margin,
        '--thumbnail-max-width': sliderContainerWidth / displayedItemsCount - 40 + 'px',
      }}>
      {title}
      <FilledButton
        className={'thumbnail-row-bracket ' + (data.length <= displayedItemsCount ? 'd-none' : '')}
        onClick={() => toNextArticle()}
        padding='12px 10px'>
        <Bracket rotation='270deg' marginLeft='0' />
      </FilledButton>
      <FilledButton
        className={'thumbnail-row-bracket ' + (data.length <= displayedItemsCount ? 'd-none' : '')}
        onClick={() => toNextArticle(true)}
        padding='12px 10px'>
        <Bracket rotation='90deg' marginLeft='0' />
      </FilledButton>
      <div className='thumbnails-row' ref={containerRef}>
        <div
          className='thumbnails-slider'
          style={{ '--slide': slidePosition + 'px', '--thumbnail-width': width > 576 ? thumbnailWidth : '' }}
          ref={sliderRef}
          onTouchStart={handleScrollStart}
          onTouchMove={handleScroll}
          onTouchEnd={handleScrollEnd}
          onMouseDown={handleScrollStart}
          onMouseMove={e => isScrolling && handleScroll(e)}
          onMouseUp={handleScrollEnd}
          onMouseLeave={handleScrollEnd}>
          {thumbnails.map((thumbnail, key) => {
            return width > 576 ? (
              <div className='thumbnails-row-unit' key={key}>
                <Thumbnail {...thumbnail} />
              </div>
            ) : (
              <Thumbnail key={key} {...thumbnail} />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default InfiniteThumbnailsRow;
