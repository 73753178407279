import { useRef, useState } from 'react';
import './MesFormations.css';
import React from 'react';
import { useEffect } from 'react';
import ReactPlayer from 'react-player';
import Card from '../../../../../components/molecules/cards/card/Card';
import ProgressionMeter from '../../../../../components/organisms/progressionMeter/ProgressionMeter';
import FormationCategory from '../../../../../components/organisms/formationsCategory/FormationCategory';
import FiltersGroup from '../../../../../components/organisms/filtersGroup/FiltersGroup';
import { useWindowSize } from '@uidotdev/usehooks';

function MesFormations() {
  const [formations, setFormations] = useState([]);
  const [videoPlayed, setVideoPlayed] = useState({
    id: 0,
    title: '',
    description: '',
    duration: 0,
    progress: 0,
    url: '',
  });
  const [categoriesProgress, setCategoriesProgress] = useState({});
  const [totalProgression, setTotalProgression] = useState(0);
  const [_, set_] = useState(0);
  const [activeCategory, setActiveCategory] = useState(0);
  const [videoFrameDimensions, setVideoFrameDimensions] = useState({
    width: '640px',
    height: '360px',
  });
  const [firstVideo, setFirstVideo] = useState(true);

  const { width } = useWindowSize();
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    fetchFormations();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVideoFrameDimensions(calcVideoFrameDimensions());
    }, 100);
  }, [width]);

  useEffect(() => {
    setTimeout(() => {
      if (videoRef.current && videoPlayed) {
        videoRef.current.seekTo(videoRef.current.getDuration() * videoPlayed.progress);

        if (!firstVideo) {
          setTimeout(() => {
            if (videoRef.current?.getInternalPlayer()?.playVideo) videoRef.current?.getInternalPlayer()?.playVideo();
            if (videoRef.current?.getInternalPlayer()?.play) videoRef.current?.getInternalPlayer()?.play();
          }, 300);
        } else {
          setFirstVideo(false);
        }
      }
    }, 1500);

    scrollToVideo();
  }, [videoPlayed]);

  useEffect(() => {
    let watchedTime = 0;
    let totalDuration = 0;

    for (const key of Object.keys(categoriesProgress)) {
      watchedTime += categoriesProgress[key].watchedTime;
      totalDuration += categoriesProgress[key].totalDuration;
    }

    setTotalProgression((watchedTime / totalDuration) * 100);
  }, [categoriesProgress]);

  async function fetchFormations() {
    const formations = [
      {
        id: 1,
        name: 'Catégorie 1',
        formations: [
          {
            id: 11,
            name: 'Formation 1',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
            videos: [1, 2, 3, 4, 5],
          },
          {
            id: 21,
            name: 'Formation 2',
            description:
              '22Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
            videos: [6, 7, 8, 11],
          },
          {
            id: 3,
            name: 'Formation 3',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
            videos: [12, 23, 30, 14, 15],
          },
        ],
      },
      {
        id: 2,
        name: 'Catégorie 2',
        formations: [
          {
            id: 1,
            name: 'Formation 11',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
            videos: [16, 22],
          },
          {
            id: 2,
            name: 'Formation 12',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
            videos: [81, 82, 83],
          },
        ],
      },
    ];

    setFormations(formations);
  }

  function getVideoPlayed() {
    return videoPlayed;
  }

  function getVideoPlayedProgress() {
    if (videoRef.current) return videoRef.current.getCurrentTime() / videoRef.current.getDuration();

    return 0;
  }

  function updateProgression() {
    set_(_ + 1);
  }

  function scrollToVideo() {
    const anchor = document.getElementById('scroller_anchor');
    if (anchor.getBoundingClientRect().top < 0) anchor.scrollIntoView({ behavior: 'smooth' }, true);
  }

  function calcVideoFrameDimensions() {
    let parentWidth = videoContainerRef.current?.offsetWidth;
    if (!videoContainerRef.current || !parentWidth || width > 1400)
      return {
        width: '640px',
        height: '360px',
      };

    if (width > 1200 && width <= 1400) parentWidth = parentWidth - 340;

    return {
      width: parentWidth + 'px',
      height: (parentWidth / 16) * 9 + 'px',
    };
  }

  return (
    <>
      <section className='container mes-formations-container'>
        <div id='scroller_anchor' />
        <h2 ref={videoContainerRef}>Denière vidéo visionnée</h2>
        <div className='row'>
          <div className='last-video-played-container'>
            <div>
              <h3>{videoPlayed.title}</h3>
              <p>{videoPlayed.description}</p>
            </div>
            <ReactPlayer
              onPause={updateProgression}
              onPlay={updateProgression}
              width={videoFrameDimensions.width}
              height={videoFrameDimensions.height}
              ref={videoRef}
              url={
                videoPlayed.url
                  ? videoPlayed.url
                  : videoPlayed.uri
                    ? require('../../../../../uploads/videos/files/' + videoPlayed.uri)
                    : ''
              }
              config={{
                youtube: {
                  playerVars: {
                    controls: 1,
                  },
                },
                file: {
                  attributes: {
                    controls: true,
                    preload: 'auto',
                  },
                },
                dailymotion: {
                  params: { controls: true },
                },
                twitch: {
                  options: {
                    controls: true,
                    autoplay: false,
                  },
                },
              }}
            />
          </div>
          {width > 1200 && (
            <Card className='progression-container'>
              <h2>Progression globale</h2>
              <ProgressionMeter percentage={totalProgression} />
            </Card>
          )}
        </div>
      </section>
      <FiltersGroup
        data={formations.map((formation, key) => {
          return {
            name: formation.name,
            onClick: setActiveCategory,
            params: [key],
          };
        })}
      />
      <section className='container'>
        {formations.map((category, key) => (
          <FormationCategory
            visible={activeCategory === key}
            data={category}
            setVideoPlayed={setVideoPlayed}
            getVideoPlayed={getVideoPlayed}
            setCategoriesProgress={setCategoriesProgress}
            getVideoPlayedProgress={getVideoPlayedProgress}
            updateProgressionCall={_}
            key={key}
          />
        ))}
        {width <= 1200 && (
          <Card className='progression-container'>
            <h2>Progression globale</h2>
            <ProgressionMeter percentage={totalProgression} />
          </Card>
        )}
      </section>
    </>
  );
}

export default MesFormations;
