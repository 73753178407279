import { toggleCollapseElement } from '../../../utils/Utils';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import './ExpendableGroup.css';
import React, { useEffect, useRef, useState } from 'react';

// data =  [
//     {
//         title: "",
//         content: ""
//     },
// ]

function ExpendableGroup({
  data,
  className = '',
  id = 'expendable-group',
  defaultVisitingCard,
  setDefaultVisitingCard,
}) {
  const [openedElement, setOpenedElement] = useState(-1);
  const parentRef = useRef(null);

  useEffect(() => {
    for (let i = 0; i < parentRef.current.children.length; i++) {
      const child = parentRef.current.children[i];
      if (child.classList.contains('active')) {
        toggleCollapseElement(child.children[1], true, 60);
      } else {
        toggleCollapseElement(child.children[1], false, 60);
      }
    }
  }, [openedElement]);

  function changeOpenedElement(index) {
    setOpenedElement(value => (value === index ? -1 : index));
  }

  //Only for visiting cards
  function handleDefaultVisitingCard(e) {
    e.preventDefault();
    e.stopPropagation();

    const checkboxes = document.getElementsByClassName('expendable-hidden-checkbox');
    for (const checkbox of checkboxes) {
      if (
        !checkbox.isSameNode(e.target) &&
        checkbox.checked
        //TODO disable default card
      );
    }

    if (e.target.checked) {
      //TODO set new default card
      setDefaultVisitingCard(e.target.getAttribute('custom-id'));
    } else {
      //TODO unset default card
      setDefaultVisitingCard(-1);
    }
  }

  return (
    <>
      <input type='hidden' id={id} value={openedElement} />
      <section className={'expendable-group-container ' + className} ref={parentRef}>
        {data.map((element, key) => {
          return (
            <div className={'expendable-container ' + (openedElement === key ? 'active' : '')} key={key}>
              <div className='expendable-top' onClick={() => changeOpenedElement(key)}>
                <div className='expendable-title'>{element.title}</div>
                {defaultVisitingCard !== undefined && (
                  <div className='expendable-checkbox-container'>
                    <label htmlFor={key}>Par défaut</label>
                    <input
                      type='checkbox'
                      custom-id={element.id}
                      className='expendable-hidden-checkbox'
                      onChange={handleDefaultVisitingCard}
                      checked={element.id == defaultVisitingCard}
                      id={key}
                    />
                    <label
                      htmlFor={key}
                      className={
                        'expendable-checkbox ' + (element.id == defaultVisitingCard ? 'expendable-checked' : '')
                      }></label>
                  </div>
                )}
                <div className='expendable-arrow'>
                  <Bracket width='20px' rotation={openedElement === key ? '0' : '180deg'} />
                </div>
              </div>
              <div className='expendable-content'>{element.content}</div>
            </div>
          );
        })}
      </section>
    </>
  );
}

export default ExpendableGroup;
