import './ParcoursTdsFdc.css';
import React, { useEffect, useContext } from 'react';
import ParcoursCarousel from '../../organisms/parcoursCarousel/ParcoursCarousel';
import BilanComptable from '../../forms/parcours/titresSocietes/step4/BilanComptable';
import ImpotsTaxes from '../../forms/parcours/titresSocietes/step5/ImpotsTaxes';
import ChargesRedevance from '../../forms/parcours/titresSocietes/step6/ChargesRedevances';
import Retraitements from '../../forms/parcours/titresSocietes/step7/Retraitements';
import { ParcoursContext } from '../../../context/ParcoursContext';

function SecondaryParcours() {
  const {
    estimationValues,
    setInputsNames,
    step,
    parcoursType,
    setStepsSummary,
    typesCommerce,
  } = useContext(ParcoursContext);

  useEffect(() => {
    initParcours();
  }, [estimationValues]);

  useEffect(() => {
    setInputsNames(array => {
      array[0] =
        parcoursType === 2 ? 'immobilisations_financieres' : typesCommerce.includes(1) ? 'ca_hebergement_ht' : 'ca_ht';
      return array;
    });
  }, [estimationValues]);

  function initParcours() {
    setInputsNames([
      parcoursType === 2 ? 'immobilisations_financieres' : typesCommerce.includes(1) ? 'ca_hebergement_ht' : 'ca_ht',
      'total_impots',
      'redevance',
      'salaire_gerant_annuel',
    ]);

    setStepsSummary([
      {
        name: `Les chiffres de votre année ${estimationValues?.infos?.date_bilan ? estimationValues?.infos?.date_bilan.slice(-4) : ''}`,
        steps: [1, 2, 3, 4],
      },
    ]);
  }

  return (
    <>
      <ParcoursCarousel step={step}>
        <BilanComptable secondaryParcours index={1} />
        <ImpotsTaxes secondaryParcours index={2} />
        <ChargesRedevance secondaryParcours index={3} />
        <Retraitements secondaryParcours index={4} />
      </ParcoursCarousel>
    </>
  );
}

export default SecondaryParcours;
