import { useContext, useState } from 'react';
import './FormationCategory.css';
import React from 'react';
import Card from '../../molecules/cards/card/Card';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import { useRef } from 'react';
import Stopwatch from '../../atoms/icons/monCompte/stopwatch/Stopwatch';
import Check from '../../atoms/icons/general/check/Check';
import ProgressArrow from '../../atoms/icons/monCompte/progressArrow/ProgressArrow';
import { useEffect } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import AppContext from '../../../context/AppContext';

function FormationCategory({
  visible,
  data,
  className = '',
  setVideoPlayed = () => {},
  setCategoriesProgress = () => {},
  getVideoPlayedProgress = () => {},
  getVideoPlayed = () => {},
  updateProgressionCall = 0,
}) {
  const [category, setCategory] = useState(data);
  const [videos, setVideos] = useState([]);
  const [slidePosition, setSlidePosition] = useState(0);
  const [sliderDimensions, setSliderDimensions] = useState({
    width: 0,
    childrenCount: 0,
    childWidth: 0,
    padding: 0,
  });
  const [isScrolling, setIsScrolling] = useState(false);
  const [fingerStartPosition, setFingerStartPosition] = useState({
    x: 0,
    y: 0,
  });
  const [lastSlideX, setLastSlideX] = useState(0);
  const [formationsSlidePosition, setFormationsSlidePosition] = useState(Array(category.formations.length).fill(0));
  const [scrollHorizontal, setScrollHorizontal] = useState(0);

  const sliderRef = useRef(null);
  const formationSlidersRefs = useRef([]);
  const { width } = useWindowSize();
  const { toggleBodyScroll } = useContext(AppContext);

  useEffect(() => {
    fetchVideos();
    calcSliderDimensions();
  }, []);

  useEffect(() => {
    if (getVideoPlayed().id) changePlayedVideo(getVideoPlayed(), true);
  }, [updateProgressionCall]);

  async function fetchVideos() {
    let watchedTime = 0;
    let totalDuration = 0;

    let videos = [
      {
        id: 1,
        title: '1. Vidéo 1 1 (Youtube)',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 21,
        progress: 0.5,
        url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        uri: null,
      },
      {
        id: 2,
        title: '2. Vidéo 2 1 (Fichier)',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 32,
        progress: 1,
        uri: 'SampleVideo_1280x720_1mb.mp4',
        url: null,
        thumbnail: 'azef886fzreg.jpg',
      },
      {
        id: 3,
        title: '3. Vidéo 3 1 (Dailymotion)',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 21,
        progress: 0.5,
        url: 'https://www.dailymotion.com/video/x8pumk6',
        uri: null,
      },
      {
        id: 4,
        title: '4. Vidéo 4 1 (Twitch)',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 11,
        progress: 1,
        url: 'https://www.twitch.tv/videos/1985107335',
        uri: null,
        thumbnail: '872672fazefffff.jpg',
      },
      {
        id: 5,
        title: '5. Vidéo 5 1',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 11,
        progress: 0,
        uri: 'SampleVideo_1280x720_30mb.mp4',
        url: null,
      },
      {
        id: 6,
        title: '1. Vidéo 1 2',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 21,
        progress: 0.8,
        url: 'https://www.youtube.com/watch?v=WAeqwwWxq_k',
        uri: null,
        thumbnail: 'azef886fzreg.jpg',
      },
      {
        id: 7,
        title: '2. Vidéo 2 2',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 32,
        progress: 1,
        url: 'https://www.youtube.com/watch?v=iC2i9n00C68',
        uri: null,
      },
      {
        id: 8,
        title: '3. Vidéo 3 2',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 21,
        progress: 0.5,
        uri: 'SampleVideo_1280x720_30mb.mp4',
        url: null,
        thumbnail: '872672fazefffff.jpg',
      },
      {
        id: 10,
        title: '4. Vidéo 4 2',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 11,
        progress: 1,
        url: 'https://www.youtube.com/watch?v=rntZOALPknU',
        uri: null,
        thumbnail: '872672fazefffff.jpg',
      },
      {
        id: 11,
        title: '5. Vidéo 5 2',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 11,
        progress: 0,
        url: 'https://www.youtube.com/watch?v=mqBn7jJ_p08',
        uri: null,
      },
      {
        id: 12,
        title: '1. Vidéo 1 3',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 21,
        progress: 0.8,
        url: 'https://www.youtube.com/watch?v=1_pfrz4EAyQ',
        uri: null,
        thumbnail: 'azef886fzreg.jpg',
      },
      {
        id: 23,
        title: '2. Vidéo 2 3',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 32,
        progress: 1,
        url: 'https://www.youtube.com/watch?v=6XGj35f-row',
        uri: null,
      },
      {
        id: 30,
        title: '3. Vidéo 3 3',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 21,
        progress: 0.5,
        url: 'https://www.youtube.com/watch?v=3kRyQsFRj3E',
        uri: null,
        thumbnail: 'azef886fzreg.jpg',
      },
      {
        id: 14,
        title: '4. Vidéo 4 3',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 11,
        progress: 1,
        url: 'https://www.youtube.com/watch?v=XwJ_PLPy0mM',
        uri: null,
      },
      {
        id: 15,
        title: '5. Vidéo 5 3',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 11,
        progress: 0,
        url: 'https://www.youtube.com/watch?v=K8hJYEFQusA',
        uri: null,
        thumbnail: 'azef886fzreg.jpg',
      },
      {
        id: 16,
        title: '1. Vidéo 11 4',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 21,
        progress: 0.8,
        url: 'https://www.youtube.com/watch?v=BVBbiRRno0s',
        uri: null,
      },
      {
        id: 22,
        title: '2. Vidéo 12 4',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 32,
        progress: 1,
        url: 'https://www.youtube.com/watch?v=wlBWMSv7EcY',
        uri: null,
        thumbnail: 'azef886fzreg.jpg',
      },
      {
        id: 81,
        title: '1. Vidéo 11 5',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 21,
        progress: 0.8,
        uri: null,
        url: 'https://www.youtube.com/watch?v=1vcJYZnKpOo',
        thumbnail: '872672fazefffff.jpg',
      },
      {
        id: 82,
        title: '2. Vidéo 12 5',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 32,
        progress: 1,
        url: 'https://www.youtube.com/watch?v=vbGtni1d96A',
        uri: null,
        thumbnail: '872672fazefffff.jpg',
      },
      {
        id: 83,
        title: '3. Vidéo 13 5',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis diam nec neque molestie vulputate. Curabitur risus eros, bibendum eu dapibus ac, scelerisque a libero. Vestibulum ullamcorper, ante eu laoreet varius, lorem enim varius lacus, sit amet dictum arcu urna vitae sapien.',
        duration: 21,
        progress: 0.5,
        url: 'https://www.youtube.com/watch?v=OphlzopH7Jc',
        uri: null,
        thumbnail: 'azef886fzreg.jpg',
      },
    ];

    videos = videos.filter(video =>
      category.formations.map(formation => formation.videos.includes(video.id)).reduce((a, b) => a || b)
    );

    const playedVideoId = 1;
    const playedVideo = videos.find(video => video.id === playedVideoId);

    for (const video of videos) {
      watchedTime += video.duration * video.progress;
      totalDuration += video.duration;
    }

    setCategoriesProgress(categories => {
      return {
        ...categories,
        [category.name]: {
          watchedTime: watchedTime,
          totalDuration: totalDuration,
        },
      };
    });
    setVideos(videos);
    if (playedVideo) setVideoPlayed(playedVideo);
  }

  function calcSliderDimensions() {
    setTimeout(() => {
      if (sliderRef.current) {
        setSliderDimensions({
          width: sliderRef.current.offsetWidth,
          childrenCount: sliderRef.current.children.length,
          childWidth:
            sliderRef.current.children[0] &&
            sliderRef.current.children[0].getBoundingClientRect().width +
              Number(window.getComputedStyle(sliderRef.current.children[0]).marginLeft.slice(0, -2)) * 2,
          padding: 0,
        });
      }
    }, 100);
  }

  function toNextArticle(upper) {
    let value = 0;

    if (upper) value = Math.ceil((slidePosition + 10) / sliderDimensions.childWidth) * sliderDimensions.childWidth;
    else value = Math.floor((slidePosition - 10) / sliderDimensions.childWidth) * sliderDimensions.childWidth;

    setSlidePosition(value);
  }

  function isAtFirstPosition() {
    return slidePosition === 0;
  }

  function isAtLastPosition() {
    return slidePosition === sliderDimensions.childWidth * (sliderDimensions.childrenCount - 1);
  }

  function getVideosByFormation(formation) {
    return videos.filter(video => formation.videos.includes(video.id));
  }

  function calcProgressionFromVideos(videos) {
    let totalDuration = 0;
    let watchedTime = 0;

    for (const video of videos) {
      watchedTime += video.duration * video.progress;
      totalDuration += video.duration;
    }
    return watchedTime / totalDuration;
  }

  function calcFormationDuration(formation) {
    let tmp = 0;
    const formationVideos = videos.filter(video => formation.videos.includes(video.id));

    for (const video of formationVideos) {
      tmp += video.duration;
    }

    const hours = Math.floor(tmp / 60);
    const minutes = tmp % 60;

    return hours ? `${hours}h${minutes.toString().padStart(2, '0')}` : `${minutes}min`;
  }

  function updateProgression(videos) {
    let watchedTime = 0;
    let totalDuration = 0;

    for (const video of videos) {
      watchedTime += video.duration * video.progress;
      totalDuration += video.duration;
    }

    setCategoriesProgress(categories => {
      return {
        ...categories,
        [category.name]: {
          watchedTime: watchedTime,
          totalDuration: totalDuration,
        },
      };
    });
  }

  function changePlayedVideo(video, force = false) {
    if ((video.id === getVideoPlayed().id && !force) || lastSlideX !== fingerStartPosition.x) return;

    const progress = getVideoPlayedProgress();
    const videoPlayedProgress = progress > 0.9 ? 1 : progress < 0.1 ? 0 : progress;
    const videoPlayed = getVideoPlayed();
    const updatedVideos = [
      ...videos.filter(value => value.id != videoPlayed.id),
      { ...videoPlayed, progress: videoPlayedProgress },
    ];

    updateProgression(updatedVideos);

    setVideos(updatedVideos);
    setVideoPlayed(video);
  }

  function handleScroll(e, inc) {
    const fingerPosition =
      e.type === 'mousemove'
        ? {
            x: e.pageX,
            y: e.pageY,
          }
        : {
            x: e.touches[0].screenX,
            y: e.touches[0].screenY,
          };
    const slideX = fingerStartPosition.x - fingerPosition.x;
    const slideY = fingerStartPosition.y - fingerPosition.y;

    switch (scrollHorizontal) {
      case 0:
        if (Math.abs(slideX) > Math.abs(slideY)) {
          setScrollHorizontal(1);
          toggleBodyScroll(false);
        } else setScrollHorizontal(-1);
        break;
      case -1:
        break;
      case 1:
        let tmp = formationsSlidePosition;
        tmp[inc] = formationsSlidePosition[inc] + lastSlideX - fingerPosition.x;

        if (tmp[inc] < -50) tmp[inc] = -50;
        if (tmp[inc] > (formationSlidersRefs.current[inc].children.length - 1) * 320 + 50)
          tmp[inc] = (formationSlidersRefs.current[inc].children.length - 1) * 320 + 50;

        setScrollHorizontal(1);
        setFormationsSlidePosition(tmp);
        setLastSlideX(fingerPosition.x);
    }
  }

  function handleScrollStart(e, inc) {
    if (!formationSlidersRefs.current || !formationSlidersRefs.current[inc]) {
      return;
    }

    formationSlidersRefs.current[inc].style.transition = 'none';

    if (e.type === 'mousedown') {
      setFingerStartPosition({ x: e.pageX, y: e.pageY });
      setLastSlideX(e.pageX);
    } else {
      setFingerStartPosition({ x: e.touches[0].screenX, y: e.touches[0].screenY });
      setLastSlideX(e.touches[0].screenX);
    }

    setIsScrolling(true);
  }

  function handleScrollEnd(inc) {
    setTimeout(() => {
      toggleBodyScroll(true);

      if (!formationSlidersRefs.current || !formationSlidersRefs.current[inc]) return;

      formationSlidersRefs.current[inc].style.transition = 'right 0.3s';
      let tmp = formationsSlidePosition;
      tmp[inc] = getClosestVideo(fingerStartPosition.x > lastSlideX, inc);

      setScrollHorizontal(0);
      setFormationsSlidePosition(tmp);
      setIsScrolling(false);
    }, 20);
  }

  function getClosestVideo(upper, inc) {
    let breakpoint = Math.floor(formationsSlidePosition[inc] / 320) * 320;
    if (upper) breakpoint += 320;

    breakpoint =
      breakpoint < 0
        ? 0
        : breakpoint > (formationSlidersRefs.current[inc].children.length - 1) * 320
          ? (formationSlidersRefs.current[inc].children.length - 1) * 320
          : breakpoint;

    return breakpoint;
  }

  return (
    <Card padding={visible ? '30px' : '0'} className={'category-container ' + (visible ? 'mb-lg' : 'hidden-category')}>
      <h2>{category.name}</h2>
      <section className={'components-row-container w-100 formations-row ' + className}>
        <div className='components-row '>
          <div
            className='components-slider formations-slider'
            style={{ '--slide': slidePosition + 'px', '--padding': sliderDimensions.padding + 'px' }}
            ref={sliderRef}>
            {category.formations.map((formation, key) => {
              return (
                <React.Fragment key={key}>
                  <div className='formation-container' key={formation.id}>
                    <div>
                      {width <= 576 && <h3>{formation.name}</h3>}
                      <div className='formation-progress-container'>
                        <ProgressArrow />
                        <div>
                          <p>Progression</p>
                          <div
                            className='progress-bar'
                            style={{
                              '--progression':
                                calcProgressionFromVideos(videos.filter(video => formation.videos.includes(video.id))) *
                                  100 +
                                '%',
                            }}
                          />
                        </div>
                        <Stopwatch />
                        <p>
                          Durée
                          <br />
                          {calcFormationDuration(formation)}
                        </p>
                      </div>
                      {width > 576 && <h3>{formation.name}</h3>}
                      <p>{formation.description}</p>
                    </div>
                    <div
                      className='formation-videos-container'
                      ref={el => (formationSlidersRefs.current[key] = el)}
                      onTouchStart={e => handleScrollStart(e, key)}
                      onTouchMove={e => isScrolling && handleScroll(e, key)}
                      onTouchEnd={() => isScrolling && handleScrollEnd(key)}
                      onMouseDown={e => handleScrollStart(e, key)}
                      onMouseMove={e => isScrolling && handleScroll(e, key)}
                      onMouseUp={() => isScrolling && handleScrollEnd(key)}
                      onMouseLeave={e => isScrolling && handleScrollEnd(e, key)}
                      style={{ '--slide': formationsSlidePosition[key] + 'px' }}>
                      {getVideosByFormation(formation)
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((video, key) => {
                          return (
                            <div
                              className='video-container'
                              key={key}
                              onClick={() => {
                                changePlayedVideo(video);
                              }}>
                              <div className='video-thumbnail-container'>
                                <img
                                  src={require(
                                    '../../../uploads/videos/thumbnails/' +
                                      (video.thumbnail ? video.thumbnail : 'default_thumbnail.jpg')
                                  )}
                                  alt='Miniature de vidéo'
                                />
                                <div className='play-button' />
                                <div className='video-duration'>{video.duration} min</div>
                                <div
                                  className='video-progress-bar'
                                  style={{
                                    '--progression': video.progress * 100 + '%',
                                  }}
                                />
                                {video.progress === 1 && (
                                  <div className='video-completed'>
                                    <Check />
                                  </div>
                                )}
                              </div>
                              <p>{video.title}</p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {key !== category.formations.length - 1 && width <= 576 && <div className='formation-hr' />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <FilledButton
          className={'formations-row-bracket ' + (isAtFirstPosition() ? 'd-none' : '')}
          onClick={() => toNextArticle()}
          padding='12px 10px'>
          <Bracket rotation='270deg' marginLeft='0' />
        </FilledButton>
        <FilledButton
          className={'formations-row-bracket ' + (isAtLastPosition() ? 'd-none' : '')}
          onClick={() => toNextArticle(true)}
          padding='12px 10px'>
          <Bracket rotation='90deg' marginLeft='0' />
        </FilledButton>
      </section>
    </Card>
  );
}

export default FormationCategory;
