import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Radio from '../../../../molecules/formComponents/radio/Radio';

import {
  max2500Validation,
  numberValidation,
  defaultValidation,
} from '../../../../../utils/formValidation/FormValidation';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import { removeSpaces } from '../../../../../utils/Utils';

function CaracteristiquesLocalFdc({ id, index }) {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const Form = { register, setValue, watch };
  const {
    dependanceLocalCommercial,
    moveToNextStep,
    step,
    setStep,
    submitFormsFdc,
    getStepToGo,
    setGoToStep,
    postValues,
    fillSelectsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const description_locaux = watch('description_locaux_checkbox');

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillSelectsFromEstimationValues(
      'qualite_local',
      ['local_angle', 'local_plain_pied', 'equipement_exterieur', 'reserve', 'stationnement_facile'],
      setValue,
      estimationValues
    );
    setValue('largeur_facade', estimationValues?.qualite_local?.largeur_facade);
    setValue('note_step_9', estimationValues?.notes?.note_step_9);

    if (estimationValues?.qualite_local?.description_locaux) {
      setValue('description_locaux_checkbox', true);
      setValue('description_locaux', estimationValues?.qualite_local?.description_locaux);
    }
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      qualite_local: {
        largeur_facade: !dependanceLocalCommercial ? removeSpaces(values.largeur_facade, 1) : null,
        local_angle: { id: !dependanceLocalCommercial ? values.local_angle : null },
        local_plain_pied: { id: !dependanceLocalCommercial ? values.local_plain_pied : null },
        equipement_exterieur: { id: !dependanceLocalCommercial ? values.equipement_exterieur : null },
        reserve: { id: !dependanceLocalCommercial ? values.reserve : null },
        stationnement_facile: { id: !dependanceLocalCommercial ? values.stationnement_facile : null },
        description_locaux: !dependanceLocalCommercial
          ? values.description_locaux_checkbox
            ? values.description_locaux
            : null
          : null,
      },
      notes: {
        note_step_9: values.note_step_9,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>caractéristiques du local d'activité</h2>
        <p className={dependanceLocalCommercial ? 'mt-3' : 'd-none'}>
          Pas besoin de remplir cette étape si vous ne disposez pas de local commercial !
        </p>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            visible={!dependanceLocalCommercial}
            label='largeur de façade (en mètres linéaires) si local avec pignon sur rue'
            name='largeur_facade'
            error={errors?.largeur_facade && errors.largeur_facade.message}
            validation={numberValidation}
          />
          <div className='w-100' />
        </div>
        <div className='row-1000'>
          <Radio
            visible={!dependanceLocalCommercial}
            label='local d’angle'
            direction='column'
            register={register}
            error={errors?.local_angle && errors.local_angle.message}
            name='local_angle'
            content={[
              {
                label: 'Sans objet',
                value: 3,
              },
              {
                label: 'Non',
                value: 2,
              },
              {
                label: 'Oui',
                value: 1,
              },
            ]}
          />
          <Radio
            visible={!dependanceLocalCommercial}
            label='local de plain-pied'
            direction='column'
            register={register}
            error={errors?.local_plain_pied && errors.local_plain_pied.message}
            name='local_plain_pied'
            content={[
              {
                label: 'Sans objet',
                value: 3,
              },
              {
                label: 'Non',
                value: 2,
              },
              {
                label: 'Oui',
                value: 1,
              },
            ]}
          />
        </div>
        <div className='row-1000'>
          <Radio
            visible={!dependanceLocalCommercial}
            label='terrasse / jardin / terrain / piscine'
            direction='column'
            register={register}
            error={errors?.equipement_exterieur && errors.equipement_exterieur.message}
            name='equipement_exterieur'
            content={[
              {
                label: 'Sans objet',
                value: 3,
              },
              {
                label: 'Non',
                value: 2,
              },
              {
                label: 'Oui',
                value: 1,
              },
            ]}
          />
          <Radio
            visible={!dependanceLocalCommercial}
            label='réserve / espace de stockage'
            direction='column'
            register={register}
            error={errors?.reserve && errors.reserve.message}
            name='reserve'
            content={[
              {
                label: 'Sans objet',
                value: 3,
              },
              {
                label: 'Non',
                value: 2,
              },
              {
                label: 'Oui',
                value: 1,
              },
            ]}
          />
        </div>
        <div className='row-1000'>
          <Radio
            visible={!dependanceLocalCommercial}
            label='stationnement facile'
            direction='column'
            register={register}
            error={errors?.stationnement_facile && errors.stationnement_facile.message}
            name='stationnement_facile'
            content={[
              {
                label: 'Sans objet',
                value: 3,
              },
              {
                label: 'Non',
                value: 2,
              },
              {
                label: 'Oui',
                value: 1,
              },
            ]}
          />
          <div className='w-100' />
        </div>
        <Checkbox
          visible={!dependanceLocalCommercial}
          name='description_locaux_checkbox'
          label='Je souhaite ajouter une description des locaux'
          register={register}
        />
        <Textarea
          error={errors?.description_locaux && errors.description_locaux.message}
          validation={{ ...defaultValidation, ...max2500Validation }}
          visible={!dependanceLocalCommercial && description_locaux}
          register={register}
          name='description_locaux'
          label='description des locaux'
        />
        <Textarea
          error={errors?.note_step_9 && errors.note_step_9.message}
          validation={max2500Validation}
          visible={!dependanceLocalCommercial}
          register={register}
          name='note_step_9'
          label='notes et remarques'
          className='mt-md'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default CaracteristiquesLocalFdc;
