import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { max2500Validation, numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput.js';
import Info from '../../../../atoms/icons/general/info/Info.js';
import { ParcoursContext } from '../../../../../context/ParcoursContext.js';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { removeSpaces } from '../../../../../utils/Utils.js';

function ImpotsTaxes({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const Form = { watch, setValue, register };
  const {
    showTooltip,
    moveToNextStep,
    step,
    getStepToGo,
    setGoToStep,
    setStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'impots_taxes',
      ['total_impots', 'csg_crds_exploitant', 'salaires', 'charges_sociales'],
      setValue,
      estimationValues
    );
    fillInputsFromEstimationValues('remunerations', ['salaires', 'charges_sociales'], setValue, estimationValues);
    if (!secondaryParcours) setValue('note_step_5', estimationValues?.notes?.note_step_5);
  }, [estimationValues]);

  async function formSubmit(values) {
    const tmp = {
      impots_taxes: {
        total_impots: removeSpaces(values.total_impots, 1),
        csg_crds_exploitant: removeSpaces(values.csg_crds_exploitant, 1),
      },
      remunerations: {
        salaires: removeSpaces(values.salaires, 1),
        charges_sociales: removeSpaces(values.charges_sociales, 1),
      },
    };
    if (!secondaryParcours) tmp.notes = { note_step_5: values.note_step_5 };

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>impôts et taxes</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            label={
              <>
                total impots et taxes (hors impot société)
                <Info onClick={() => showTooltip('4_Total_impots.webp')} />
              </>
            }
            name='total_impots'
            error={errors?.total_impots && errors.total_impots.message}
            validation={numberValidation}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            label={
              <>
                csg crds exploitant (s'il y a lieu)
                <Info onClick={() => showTooltip('4bis_CSG.webp')} />
              </>
            }
            name='csg_crds_exploitant'
            error={errors?.csg_crds_exploitant && errors.csg_crds_exploitant.message}
            validation={numberValidation}
            icon='euro'
          />
        </div>
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>rémunérations</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            label={
              <>
                total des rémunérations annuelles (salariés + dirigeants)
                <Info onClick={() => showTooltip('5_Total_rem_annuelles.webp')} />
              </>
            }
            name='salaires'
            error={errors?.salaires && errors.salaires.message}
            validation={numberValidation}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            label={
              <>
                total des charges sociales annuelles (salariés + dirigeants)
                <Info onClick={() => showTooltip('5bis_Total_chg_sociales_annuelles.webp')} />
              </>
            }
            name='charges_sociales'
            error={errors?.charges_sociales && errors.charges_sociales.message}
            type='nmber'
            validation={numberValidation}
            icon='euro'
            onFocus={() => {
              if (step == index + 1 && secondaryParcours) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && secondaryParcours) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        </div>
        {!secondaryParcours && (
          <Textarea
            register={register}
            error={errors?.note_step_5 && errors.note_step_5.message}
            validation={max2500Validation}
            name='note_step_5'
            label='notes et remarques'
            className='mt-md'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        )}
      </fieldset>
    </form>
  );
}

export default ImpotsTaxes;
