import { useForm } from 'react-hook-form';
import VisitingCard from '../../../organisms/visitingCard/VisitingCard';
import './VisitingCardForm.css';
import React, { useEffect, useRef, useState } from 'react';
import Input from '../../../molecules/formComponents/input/Input';
import Hr from '../../../molecules/hr/Hr';
import Textarea from '../../../molecules/formComponents/textarea/Textarea';
import FileInput from '../../../molecules/formComponents/fileInput/FileInput';
import Immeuble from '../../../atoms/icons/general/immeuble/Immeuble';
import User from '../../../atoms/icons/general/user/User';
import { checkImage, isStickyElementPinned } from '../../../../utils/Utils';
import { useWindowScroll } from '@uidotdev/usehooks';

function VisitingCardForm({ visitingCardData, navbarRef, id }) {
  const { register, setValue, getValues } = useForm();

  const [visitingCard, setVisitingCard] = useState(visitingCardData);
  const [imgErrorMessage, setImgErrorMessage] = useState({
    avatar: '',
    logo: '',
  });
  const [windowScroll] = useWindowScroll();
  const cardContainerRef = useRef(null);

  useEffect(() => {
    setVisitingCard({
      ...visitingCard,
      avatar: visitingCard.avatar
        ? visitingCard.avatar.includes('/')
          ? visitingCard.avatar
          : require('../../../../uploads/visitingCards/pictures/' + visitingCard.avatar)
        : undefined,
      logo: visitingCard.logo
        ? visitingCard.logo.includes('/')
          ? visitingCard.logo
          : require('../../../../uploads/visitingCards/pictures/' + visitingCard.logo)
        : undefined,
      picture: visitingCard.picture
        ? visitingCard.picture.includes('/')
          ? visitingCard.picture
          : require('../../../../uploads/visitingCards/pictures/' + visitingCard.picture)
        : undefined,
    });

    Object.keys(getValues()).forEach(key => {
      if (key !== 'avatar' && key !== 'logo') {
        setValue(key, visitingCardData[key.split('-')[0]]);
      }
    });

    navbarRef.current.style.maxHeight = '100px';
    navbarRef.current.style.transition = 'max-height 0.3s';
  }, []);

  useEffect(() => {
    hideNavbarIfCardSticky();
  }, [windowScroll]);

  function onImageChange(e, input) {
    const file = e.target.files[0];
    if (!file) return;

    let errorMsg = checkImage(file, 20);
    if (errorMsg === '') {
      setVisitingCard({ ...visitingCard, [input]: URL.createObjectURL(file) });
      //TODO Ajax image
    }
    setImgErrorMessage({ ...imgErrorMessage, [input]: errorMsg });
  }

  function onImageClear(input) {
    setVisitingCard({ ...visitingCard, [input]: '' });
    //TODO Ajax remove image
  }

  function setVisitingCardFormInput(input, event) {
    setVisitingCard({ ...visitingCard, [input]: event.target.value });
    //TODO Ajax input value
  }

  function hideNavbarIfCardSticky() {
    if (document.getElementById('visiting-cards-expendable-group').value == id) {
      if (isStickyElementPinned(cardContainerRef, 80)) {
        navbarRef.current.style.maxHeight = '0';
        navbarRef.current.style.overflow = 'hidden';
      } else {
        navbarRef.current.style.maxHeight = '100px';
        navbarRef.current.style.overflow = '';
      }
    }
  }

  return (
    <div>
      <form>
        <Input
          name={`cardName-${id}`}
          register={register}
          label='Nom de la carte de visite'
          onBlur={e => setVisitingCardFormInput('cardName', e)}
        />
        {visitingCard.idType === 1 ? (
          <>
            {' '}
            <div className='visiting-card-profile-container' ref={cardContainerRef}>
              <VisitingCard {...visitingCard} />
            </div>
            <div className='row-1000'>
              <FileInput
                name={`avatar-${id}`}
                register={register}
                icon={<User width='25px' />}
                label='Avatar'
                onChange={e => onImageChange(e, 'avatar')}
                setValue={setValue}
                onClear={() => onImageClear('avatar')}
                error={imgErrorMessage.avatar}
              />
              <FileInput
                name={`logo-${id}`}
                icon={<Immeuble width='25px' color='var(--white)' />}
                register={register}
                label='Logo'
                onChange={e => onImageChange(e, 'logo')}
                setValue={setValue}
                onClear={() => onImageClear('logo')}
                error={imgErrorMessage.logo}
              />
            </div>
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('firstname', e)}
                name={`firstname-${id}`}
                register={register}
                label='Prénom'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('lastname', e)}
                name={`lastname-${id}`}
                register={register}
                label='Nom'
              />
            </div>
            <Input
              onBlur={e => setVisitingCardFormInput('position', e)}
              name={`position-${id}`}
              register={register}
              label='poste'
            />
            <Input
              onBlur={e => setVisitingCardFormInput('slogan', e)}
              name={`slogan-${id}`}
              register={register}
              label='slogan'
            />
            <Input
              onBlur={e => setVisitingCardFormInput('company', e)}
              name={`company-${id}`}
              register={register}
              label='société'
            />
            <Input
              onBlur={e => setVisitingCardFormInput('address1', e)}
              name={`address1-${id}`}
              register={register}
              label="ligne d'adresse 1"
            />
            <Input
              onBlur={e => setVisitingCardFormInput('address2', e)}
              name={`address2-${id}`}
              register={register}
              label="ligne d'adresse 2"
            />
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('zipCode', e)}
                name={`zipCode-${id}`}
                register={register}
                label='code postal'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('city', e)}
                name={`city-${id}`}
                register={register}
                label='ville'
              />
            </div>
            <hr className='visiting-card-form-hr' />
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('email', e)}
                name={`email-${id}`}
                register={register}
                label='e-mail'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('website', e)}
                name={`website-${id}`}
                register={register}
                label='site web'
              />
            </div>
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('mobilePhone', e)}
                name={`mobilePhone-${id}`}
                register={register}
                label='téléphone portable'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('fixedPhone', e)}
                name={`fixedPhone-${id}`}
                register={register}
                label='téléphone fixe'
              />
            </div>
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('rsac', e)}
                name={`rsac-${id}`}
                register={register}
                label='rsac'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('siret', e)}
                name={`siret-${id}`}
                register={register}
                label='siret'
              />
            </div>
            <hr className='visiting-card-form-hr' />
            <Textarea label='mentions légales' register={register} name='mentions' />
          </>
        ) : (
          <>
            <div className='imported-card-container'>
              <img className='imported-visiting-card' alt='carte importée' src={visitingCard.picture} />
              <Textarea label='mentions légales' register={register} name='mentions' />
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default VisitingCardForm;
