export const phoneValidation = {
  required: 'Ce champ est obligatoire',
  pattern: {
    value: /^[+]?[0-9]{2}[-\s.]?[0-9]{0,2}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}$/,
    message: 'Numéro de téléphone invalide',
  },
};

export const emailValidation = {
  required: 'Ce champ est obligatoire',
  pattern: {
    value: /^[\w.+]+@([\w]+\.)+[\w]{2,4}$/,
    message: 'Adresse mail invalide',
  },
};

export const selectValidation = {
  validate: (value, formValues) => value !== '_null' || 'Ce champ est obligatoire',
};

export const numberValidation = {
  required: 'Ce champ est obligatoire',
  pattern: {
    value: /^-?[0-9\s]*$/,
    message: "Ce champ n'accepte que les chiffres",
  },
};

export const dateValidation = {
  required: 'Ce champ est obligatoire',
  valueAsDate: true,
};

export const decimalValidation = {
  required: 'Ce champ est obligatoire',
  pattern: {
    value: /^-?[0-9\s]*[,|.]?[0-9]*$/,
    message: "Ce champ n'accepte que les nombres décimaux",
  },
};

export const passwordValidation = {
  required: 'Ce champ est obligatoire',
  pattern: {
    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/,
    message: 'Votre mot de passe ne respecte pas les critères de sécurité',
  },
};

export const confirmPasswordvalidation = {
  validate: (value, formValues) => value === formValues.password || 'Les mots de passe ne correspondent pas',
};

export const rate5Validation = {
  required: 'Ce champ est obligatoire',
  pattern: {
    value: /^[0-5](,\d+)?$/,
    message: 'La note doit être comprise entre 0 et 5',
  },
};

export const rate10Validation = {
  required: 'Ce champ est obligatoire',
  pattern: {
    value: /^[0-5](,\d+)?$/,
    message: 'La note doit être comprise entre 0 et 10',
  },
};

export const defaultValidation = {
  required: 'Ce champ est obligatoire',
  maxLength: {
    value: 255,
    message: 'Ce champ ne doit pas dépasser 255 caractères',
  },
};

export const max2500Validation = {
  maxLength: {
    value: 2500,
    message: 'Ce champ ne doit pas dépasser 2500 caractères',
  },
};
