import { useForm } from 'react-hook-form';
import Input from '../../components/molecules/formComponents/input/Input';
import './Test.css';
import React, { useEffect, useState } from 'react';
import CaseInput from '../../components/molecules/formComponents/caseInput/CaseInput';
import Checkbox from '../../components/molecules/formComponents/checkbox/Checkbox';
import NumberInput from '../../components/molecules/formComponents/numberInput/NumberInput';
import DoubleInput from '../../components/molecules/formComponents/doubleInput/DoubleInput';
import FileInput from '../../components/molecules/formComponents/fileInput/FileInput';
import Radio from '../../components/molecules/formComponents/radio/Radio';
import Select from '../../components/molecules/formComponents/select/Select';
import Textarea from '../../components/molecules/formComponents/textarea/Textarea';
import Toggle from '../../components/molecules/formComponents/toggle/Toggle';
import { Link } from 'react-router-dom';
import FilledButton from '../../components/molecules/buttons/filledButton/FilledButton';
import Info from '../../components/atoms/icons/general/info/Info';
import { defaultValidation, numberValidation } from '../../utils/formValidation/FormValidation';

function Test() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();
  const [visible, setVisible] = useState(true);
  const Form = { register, setValue, watch };

  return (
    <section className='page-container container'>
      <FilledButton onClick={() => setVisible(!visible)}>Toggle visibility</FilledButton>
      <form onSubmit={handleSubmit(data => console.log(data))}>
        <fieldset className='borderless-fieldset'>
          <h2>Test des formulaires</h2>
          <div className='form-subtitle'>
            A appliquer sur les valeurs obtenues à l'issue de l'estimation initiale
            <Info onClick={() => {}} />
          </div>
          <div className='row-1000'>
            <Input
              visible={visible}
              validation={numberValidation}
              register={register}
              label="Test input avec un label très long qui va dépasser la taille de l'écran  je veux qu'il passe sur 2 lignesnput avec un label très long qui va dépasser la taille de l'écran  je veux qu'il passe sur 2 lignes"
              name='test_input'
              error={errors?.test_input && errors.test_input.message}
            />
            <NumberInput
              visible={visible}
              useForm={Form}
              validation={defaultValidation}
              label='Test number input'
              name='test_number_input'
              error={errors?.test_number_input && errors.test_number_input.message}
            />
          </div>

          <div className='row-1000'>
            <Input
              visible={visible}
              validation={numberValidation}
              register={register}
              label="Test input avec un label très long qui va dépasser la taille de l'écran  je veux qu'il passe sur 2 lignesnput avec un label très long qui va dépasser la taille de l'écran  je veux qu'il passe sur 2 lignes"
              name='test_input_777'
              error={errors?.test_input && errors.test_input.message}
            />
            <NumberInput
              visible={visible}
              useForm={Form}
              validation={defaultValidation}
              label='Test number input'
              name='test_number_input'
              error={errors?.test_number_input && errors.test_number_input.message}
            />
          </div>
          <div className='row-1000'>
            <Input
            tip="Un truc utile par ici"
              visible={visible}
              className='zebi'
              register={register}
              bgColor={'var(--pale-blue)'}
              label='Test input'
              name='test_input_2'
              error={errors?.test_input_2 && errors.test_input_2.message}
            />
            <Input
              visible={visible}
              register={register}
              label={
                <>
                  charges sociales annuelles du (des) dirigeant(s)
                  <Info onClick={() => console.log('zebi')} />
                </>
              }
              name='test_input_3'
              error={errors?.test_input_3 && errors.test_input_3.message}
            />
          </div>
          <div className='row-1000'>
            <Input
              visible={visible}
              className='zebi'
              register={register}
              bgColor={'var(--pale-blue)'}
              label='Test input'
              type='date'
              name='test_input_6'
              error={errors?.test_input_6 && errors.test_input_6.message}
            />
            <Input
              visible={visible}
              icon='percent'
              register={register}
              label={
                <>
                  charges sociales annuelles du (des) dirigeant(s)
                  <Info onClick={() => console.log('zebi')} />
                </>
              }
              name='test_input_5'
              error={errors?.test_input_5 && errors.test_input_5.message}
            />
          </div>
          <div className='row-1000'>
            <Input
              visible={visible}
              className='zebi'
              register={register}
              icon='search'
              bgColor={'var(--pale-blue)'}
              label='Test input'
              name='test_input_3'
              error={errors?.test_input_3 && errors.test_input_3.message}
            />
            <Input
              visible={visible}
              register={register}
              icon='euro'
              label={
                <>
                  charges sociales annuelles du (des) dirigeant(s)
                  <Info onClick={() => console.log('zebi')} />
                </>
              }
              name='test_input_4'
              error={errors?.test_input_4 && errors.test_input_4.message}
            />
          </div>
          <Checkbox
            visible={visible}
            validation={defaultValidation}
            register={register}
            label='Test checkbox'
            name='test_checkbox'
            error={errors?.test_checkbox && errors.test_checkbox.message}
          />
          <Checkbox
            visible={visible}
            className='test-checkbox'
            validation={defaultValidation}
            register={register}
            label={
              <p>
                En cochant cette case, je reconnais avoir pris connaissance et accepté pleinement les{' '}
                <Link to='/cgv'>Conditions Générales de Vente</Link> de GALAXICO, les{' '}
                <Link to='/cgu'>Conditions Générales d'utilisation</Link> et la{' '}
                <Link to='/politique-confidentialite'>Politique de Confidentialité du Site</Link>.&nbsp;*
              </p>
            }
            name='test_checkbox_2'
            error={errors?.test_checkbox_2 && errors.test_checkbox_2.message}
          />
          <div className='row-1000'>
            <CaseInput
              visible={visible}
              tip='this is a very useful tip'
              useForm={Form}
              label='Test case input'
              name='test_case_input'
              error={errors?.test_case_input && errors.test_case_input.message}
              validation={defaultValidation}
            />
            <DoubleInput
              visible={visible}
              useForm={Form}
              validation={defaultValidation}
              label='Test double input'
              name='test_double_input'
              name1='test_double_input_1'
              error={errors?.test_double_input_1 && errors.test_double_input_1.message}
            />
          </div>
          <FileInput
            visible={visible}
            register={register}
            validation={defaultValidation}
            label='Test file input'
            name='test_file_input'
            error={errors?.test_file_input && errors.test_file_input.message}
          />
          <FileInput
            visible={visible}
            register={register}
            validation={defaultValidation}
            label='Test file input 2'
            removeButton={false}
            name='test_file_input_2'
            error={errors?.test_file_input_2 && errors.test_file_input_2.message}
          />
          <Radio
            visible={visible}
            register={register}
            label='Test radio'
            name='test_radio'
            content={[
              { label: 'Test radio 1', value: '1' },
              { label: 'Test radio 2', value: '2' },
            ]}
            error={errors?.test_radio && errors.test_radio.message}
          />
          <Select
            visible={visible}
            register={register}
            label='Test select'
            name='test_select'
            error={errors?.test_select && errors.test_select.message}>
            <option value='1'>Test select 1</option>
            <option value='2'>Test select 2</option>
          </Select>
          <Textarea
            visible={visible}
            defaultValue={`Le contenu d'une textarea\n\n et un backline`}
            register={register}
            label='Test textarea'
            name='test_textarea'
            error={errors?.test_textarea && errors.test_textarea.message}
            validation={defaultValidation}
          />
          <Toggle
            visible={visible}
            register={register}
            label='Test toggle'
            label1='Test toggle 2'
            name='test_toggle'
            error={errors?.test_toggle && errors.test_toggle.message}
          />
          <div className='row-1000'>
            <Radio
              visible={visible}
              direction='column'
              register={register}
              label='Test radio 2'
              name='test_radio_2'
              content={[
                { label: 'Test radio 1', value: '1' },
                { label: 'Test radio 2', value: '2' },
              ]}
              error={errors?.test_radio_2 && errors.test_radio_2.message}
            />
            <Input
              visible={visible}
              register={register}
              label='Test input 2'
              name='test_input_2'
              shouldStickBottom={false}
              error={errors?.test_input_2 && errors.test_input_2.message}
            />
          </div>
          <p className='form-legend'>* Champs obligatoires</p>
          <div className='centered mt-md'>
            <FilledButton type='submit'>Submit</FilledButton>
          </div>
        </fieldset>
      </form>
    </section>
  );
}

export default Test;
