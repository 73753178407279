import { Link } from 'react-router-dom';
import EmcLogo from '../../atoms/logos/emcLogo/EmcLogo';
import './Footer.css';
import React from 'react';
import RoundedDiv from '../../atoms/icons/general/roundedDiv/RoundedDiv';
import NewsletterForm from '../../forms/compte/newsletterForm/NewsletterForm';
import Heart from '../../atoms/icons/general/heart/Heart';

function Footer() {
  return (
    <>
      <section className='footer-container'>
        <RoundedDiv className='footer-top' />
        <div className='container footer-content'>
          <div>
            <EmcLogo width='300px' className='footer-logo' />
            <ul>
              <li>
                <Link to='mailto:contact@estimermoncommerce.fr'>contact@estimermoncommerce.fr</Link>
              </li>
              <li>41-43 rue de Cronstadt</li>
              <li>75015 Paris</li>
            </ul>
          </div>
          <div>
            <div className='footer-title'>Liens légaux</div>
            <ul>
              <li>
                <Link to='/politique-confidentialite'>Politique de confidentialité</Link>
              </li>
              <li>
                <Link to='/mentions-legales'>Mentions légales</Link>
              </li>
              <li>
                <Link to='/parametrage-cookies'>Gérer les cookies</Link>
              </li>
              <li>
                <Link to='/cgu'>C.G.U</Link>
              </li>
              <li>
                <Link to='/cgv'>C.G.V</Link>
              </li>
            </ul>
          </div>
          <div>
            <div className='footer-title'>Ressources</div>
            <ul>
              <li>
                <Link to='/outils-pratiques'>Outils pratiques</Link>
              </li>
              <li>
                <Link to='/lexique'>Lexique</Link>
              </li>
              <li>
                <Link to='/annonces'>Les annonces</Link>
              </li>
              <li>
                <Link to='/faq'>F.A.Q</Link>
              </li>
              <li>
                <Link to='/conseils-pros'>Conseils de pros</Link>
              </li>
              <li>
                <Link to='/methode-philosophie'>Méthode & philosophie</Link>
              </li>
              <li>
                <Link to='/guides-agents-immos'>Nos guides pour agents immos</Link>
              </li>
              <li>
                <Link to='/simulateur-credit'>Simulateur de crédit</Link>
              </li>
              <li>
                <Link to='/plan-financement'>Plan de financement</Link>
              </li>
              <li>
                <Link to='/calculateur-cotisations'>Calculateur de cotisations</Link>
              </li>
              <li>
                <Link to='/liste-activites'>Liste des activités</Link>
              </li>
              <li>
                <Link to='/exemples-rapports'>Exemples de rapports</Link>
              </li>
              <li>
                <Link to='/contact'>Nous contacter</Link>
              </li>
            </ul>
          </div>
          <div>
            <div className='footer-title'>Newsletter</div>
            <NewsletterForm />
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
