import React, { useContext, useEffect } from 'react';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import Info from '../../../../atoms/icons/general/info/Info';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import {
  decimalValidation,
  rate10Validation,
  rate5Validation,
} from '../../../../../utils/formValidation/FormValidation';
import { removeSpaces } from '../../../../../utils/Utils';

function Reputation({ id, index }) {
  const {
    register,
    watch,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const Form = { register, watch, setValue };
  const {
    showTooltip,
    typesCommerce,
    step,
    submitFormsFdc,
    postValues,
    getStepToGo,
    setGoToStep,
    fillInputsFromEstimationValues,
    estimationValues,
    setStep,
  } = useContext(ParcoursContext);
  const carac_hors_normes = watch('carac_hors_normes_checkbox');

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'e_reputation',
      ['note_google', 'note_tripadvisor', 'note_the_fork', 'note_booking', 'note_autre'],
      setValue,
      estimationValues
    );
    fillInputsFromEstimationValues('coef_ponderation', ['coef_1', 'coef_2', 'coef_3'], setValue, estimationValues);

    setValue('diplome_certification', estimationValues?.diplome_certification?.id + '');
    setValue('note_step_13', estimationValues?.notes?.note_step_13);

    if (estimationValues?.carac_hors_normes) {
      setValue('carac_hors_normes_checkbox', true);
      setValue('carac_hors_normes', estimationValues?.carac_hors_normes);
    }
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      e_reputation: {
        note_google: removeSpaces(values.note_google, 1),
        note_tripadvisor: removeSpaces(values.note_tripadvisor, 1),
        note_the_fork: removeSpaces(values.note_the_fork, 1),
        note_booking: removeSpaces(values.note_booking, 1),
        note_autre: removeSpaces(values.note_autre),
      },
      diplome_certification: { id: values.diplome_certification },
      coef_ponderation: {
        coef_1: removeSpaces(values.coef_1, 1),
        coef_2: removeSpaces(values.coef_2, 1),
        coef_3: removeSpaces(values.coef_3, 1),
      },
      carac_hors_normes: values.carac_hors_normes_checkbox ? values.carac_hors_normes : null,
      notes: {
        note_step_13: values.note_step_13,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    }
  }
  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2 className='flex gap-sm'>
          e-réputation
          <Info width='23px' margin='0 0 -3px 10px' onClick={() => showTooltip('14_Ereputation.webp')} />
        </h2>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            className={typesCommerce.includes(2) || typesCommerce.includes(4) ? '' : 'w-0'}
            visible={typesCommerce.includes(2) || typesCommerce.includes(4)}
            label='tripadvisor (note de 1 à 5)'
            error={errors?.note_tripadvisor && errors.note_tripadvisor.message}
            name='note_tripadvisor'
            validation={rate5Validation}
          />
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            label='avis google (note de 1 à 5)'
            error={errors?.note_google && errors.note_google.message}
            name='note_google'
            type='number'
            validation={rate5Validation}
          />
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            className={typesCommerce.includes(2) || typesCommerce.includes(4) ? '' : 'w-0'}
            visible={typesCommerce.includes(2) || typesCommerce.includes(4)}
            label='the fork (note de 1 à 10)'
            error={errors?.note_the_fork && errors.note_the_fork.message}
            name='note_the_fork'
            type='number'
            validation={rate10Validation}
          />
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            className={typesCommerce.includes(4) ? '' : 'w-0'}
            visible={typesCommerce.includes(4)}
            label='booking (note de 1 à 10)'
            error={errors?.note_booking && errors.note_booking.message}
            name='note_booking'
            type='number'
            validation={rate10Validation}
          />
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            label='autres (note de 1 à 5)'
            error={errors?.note_autre && errors.note_autre.message}
            name='note_autre'
            type='number'
            validation={rate5Validation}
          />
        </div>
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>diplômes & certifications</h2>
        <Radio
          label={
            <>
              l’activité nécessite-t-elle un diplôme, une certification, une carte professionnelle ou une licence ?
              (hormis lic iii et iv et agréments buraliste)
              <Info onClick={() => showTooltip('14bis_Diplome.webp')} />
            </>
          }
          error={errors?.diplome_certification && errors.diplome_certification.message}
          register={register}
          content={[
            { value: 1, label: 'Non' },
            { value: 2, label: 'Oui' },
          ]}
          name='diplome_certification'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>activité</h2>
        <Checkbox
          label={
            <>
              l'affaire possède des caractéristiques véritablement exceptionnelles ?{' '}
              <Info onClick={() => showTooltip('15_Caracteristiques_exceptionnelles.webp')} />
            </>
          }
          name='carac_hors_normes_checkbox'
          register={register}
        />
        <Textarea
          visible={carac_hors_normes}
          label='décrivez-nous lesquelles'
          register={register}
          name='carac_hors_normes'
          error={errors?.carac_hors_normes && errors.carac_hors_normes.message}
          validation={{
            required: 'Ce champ est obligatoire',
          }}
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>notes et remarques</h2>
        <Textarea
          name='note_step_13'
          register={register}
          label="vous pouvez ajouter ici des notes, qui apparaîtront sur le rapport d'estimation"
          error={errors?.note_step_13 && errors.note_step_13.message}
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>coefficients de pondération</h2>
        <div className='form-subtitle'>
          A appliquer sur les valeurs obtenues à l'issue de l'estimation initiale
          <Info onClick={() => showTooltip('TutocoefponderateurfinalFDCetTitres.webp')} />
        </div>
        <NumberInput
          useForm={Form}
          label="fourchette de volume d'activité moyen"
          name='coef_1'
          error={errors?.coef_1 && errors.coef_1.message}
          icon='percent'
          validation={decimalValidation}
        />
        <NumberInput
          useForm={Form}
          label='fourchette de rentabilité moyenne'
          name='coef_2'
          error={errors?.coef_2 && errors.coef_2.message}
          icon='percent'
          validation={decimalValidation}
        />
        <NumberInput
          useForm={Form}
          label="fourchette de rentabilité proportionnelle au volume d'activité"
          name='coef_3'
          error={errors?.coef_3 && errors.coef_3.message}
          icon='percent'
          validation={decimalValidation}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default Reputation;
