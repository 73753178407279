import Magnifier from '../../atoms/icons/general/magnifier/Magnifier';
import Cross from '../../atoms/icons/general/cross/Cross';
import './Searchbar.css';
import React, { useEffect, useRef, useState } from 'react';
import { isStickyElementPinned } from '../../../utils/Utils';

function Searchbar({
  className = '',
  onChange = () => {},
  width = '50%',
  placeholder,
  bgColor = 'var(--beige)',
  topTreshold = -1,
  resetValueTrigger = false,
}) {
  const [value, setValue] = useState('');
  const [pinned, setPinned] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    window.addEventListener('scroll', handleStickyScroll);
    return () => {
      window.removeEventListener('scroll', handleStickyScroll);
    };
  }, [topTreshold]);

  useEffect(() => {
    resetValue();
  }, [resetValueTrigger]);

  function handleStickyScroll() {
    setPinned(isStickyElementPinned(inputRef, topTreshold));
  }

  function resetValue() {
    setValue('');
    onChange('');
  }

  return (
    <div
      className={'search-bar-container ' + (pinned ? 'pinned ' : '') + className}
      style={{ '--width': width, '--background-color': bgColor }}>
      <input
        ref={inputRef}
        value={value}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        type='text'
        placeholder={placeholder}
      />
      <Magnifier />
      {value && <Cross color='var(--dark-blue)' width='20px' className='searchbar-cross' onClick={resetValue} />}
    </div>
  );
}

export default Searchbar;
