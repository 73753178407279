import { useWindowSize } from '@uidotdev/usehooks';
import './FiltersGroup.css';
import React, { useEffect, useState, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import { get } from 'react-hook-form';

// data = [
//     {
//         name: '',
//         onClick: () => {},
//         params: [],
//         to: '',
//     },
// ];

function FiltersGroup({ data, className = '', changeActiveTabIndex }) {
  const [activeLink, setActiveLink] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [slidePosition, _setSlidePosition] = useState(0);
  const [sliderDimensions, setSliderDimensions] = useState({
    width: 0,
    childrenCount: 0,
    childrenWidth: [],
    gap: 0,
  });

  const sliderRef = useRef(null);
  const containerRef = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (changeActiveTabIndex !== undefined) {
      setActiveLink(changeActiveTabIndex);
    }
  }, [changeActiveTabIndex]);

  useEffect(() => {
    data.forEach(filter => {
      if (!filter.params) filter.params = [];
    });
    calcSliderDimensions();
  }, [data]);

  function getUsableData(data) {
    return data.map((filter, key) => {
      filter.index = key;
      return filter;
    });
  }
  function handleScroll() {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();

    if (rect.top <= 0) setIsSticky(true);
    else setIsSticky(false);
  }

  function calcSliderDimensions() {
    setTimeout(() => {
      if (!sliderRef.current) return;

      const childrenWidth = [];

      for (let i = 0; i < sliderRef.current.children.length; i++) {
        childrenWidth.push(sliderRef.current.children[i].offsetWidth);
      }

      setSliderDimensions({
        width: sliderRef.current.offsetWidth,
        childrenCount: sliderRef.current.children.length,
        childrenWidth: childrenWidth,
        gap: 20,
      });
    }, 100);
  }

  function getMaxSlidePosition() {
    if (!containerRef.current) return 0;

    let maxWidth = 0;
    for (let i = 0; i < sliderDimensions.childrenCount; i++) {
      maxWidth += sliderDimensions.childrenWidth[i] + sliderDimensions.gap;
      if (maxWidth > sliderDimensions.width - containerRef.current.offsetWidth) break;
    }
    return maxWidth;
  }

  function setSlidePosition(value) {
    value = Math.max(0, value);
    _setSlidePosition(Math.min(value, getMaxSlidePosition()));
  }

  function toPreviousFilter() {
    const slidingValues = calcSlidingValues();
    let visibleDistance = 0;

    for (let i = slidingValues.leftCount - 1; i >= 0; i--) {
      if (
        visibleDistance + sliderDimensions.childrenWidth[i] + sliderDimensions.gap >
        slidingValues.containerDimensions.width
      )
        break;
      visibleDistance += sliderDimensions.childrenWidth[i] + sliderDimensions.gap;
    }
    setSlidePosition(slidingValues.leftDistance - visibleDistance);
  }

  function toNextFilter() {
    const slidingValues = calcSlidingValues();
    let visibleDistance = 0;

    for (let i = slidingValues.leftCount; i < slidingValues.leftCount + slidingValues.visibleCount; i++) {
      visibleDistance += sliderDimensions.childrenWidth[i] + sliderDimensions.gap;
    }

    setSlidePosition(slidingValues.leftDistance + visibleDistance);
  }

  function calcSlidingValues() {
    const indexes = {
      leftCount: 0,
      visibleCount: 0,
    };
    const containerDimensions = containerRef.current.getBoundingClientRect();
    let leftDistance = 0;

    for (let i = 0; i < sliderRef.current.children.length; i++) {
      const dimensions = sliderRef.current.children[i].getBoundingClientRect();
      if (dimensions.left < containerDimensions.left) indexes.leftCount++;
      else if (dimensions.right > containerDimensions.right);
      else indexes.visibleCount++;
    }

    for (let i = 0; i < indexes.leftCount; i++) {
      leftDistance += sliderDimensions.childrenWidth[i] + sliderDimensions.gap;
    }

    return {
      leftCount: indexes.leftCount,
      visibleCount: indexes.visibleCount,
      leftDistance: leftDistance,
      containerDimensions: containerDimensions,
    };
  }

  function isAtFirstPosition() {
    return slidePosition === 0 || sliderDimensions.width <= containerRef.current.offsetWidth;
  }

  function isAtLastPosition() {
    return slidePosition === getMaxSlidePosition() || sliderDimensions.width <= containerRef.current.offsetWidth;
  }

  const Filter = ({ filter }) => {
    return filter.to ? (
      <HashLink
        smooth
        custom-title={filter.name}
        className={'filter ' + (activeLink === filter.index ? 'active' : '')}
        onClick={() => {
          setActiveLink(filter.index);
          filter.onClick && filter.onClick(...filter.params);
        }}
        to={filter.to}>
        {filter.name}
      </HashLink>
    ) : (
      <div
        custom-title={filter.name}
        className={'filter ' + (activeLink === filter.index ? 'active' : '')}
        onClick={() => {
          setActiveLink(filter.index);
          filter.onClick && filter.onClick(...filter.params);
        }}>
        {filter.name}
      </div>
    );
  };

  return (
    <section className={'filters-group-container ' + className + (isSticky ? ' pinned' : '')} ref={containerRef}>
      <div className='container'>
        {width > 992 ? (
          <>
            <div className='components-row-container relative'>
              <div className='components-row' ref={containerRef}>
                <div className='components-slider' ref={sliderRef} style={{ '--slide': slidePosition + 'px' }}>
                  {getUsableData(data).map((filter, key) => {
                    return <Filter filter={filter} key={key} />;
                  })}
                </div>
              </div>
              <FilledButton
                className={'formations-row-bracket ' + (isAtFirstPosition() ? 'd-none' : '')}
                onClick={() => toPreviousFilter()}
                padding='12px 10px'>
                <Bracket rotation='270deg' marginLeft='0' />
              </FilledButton>
              <FilledButton
                className={'formations-row-bracket ' + (isAtLastPosition() ? 'd-none' : '')}
                onClick={() => toNextFilter()}
                padding='12px 10px'>
                <Bracket rotation='90deg' marginLeft='0' />
              </FilledButton>
            </div>
          </>
        ) : (
          <div>
            {getUsableData(data).map((filter, key) => {
              return <Filter filter={filter} key={key} />;
            })}
          </div>
        )}
      </div>
    </section>
  );
}

export default FiltersGroup;
