import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../../molecules/formComponents/input/Input';
import Select from '../../../molecules/formComponents/select/Select';
import {
  confirmPasswordvalidation,
  defaultValidation,
  emailValidation,
  passwordValidation,
  phoneValidation,
} from '../../../../utils/formValidation/FormValidation';
import Checkbox from '../../../molecules/formComponents/checkbox/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';
import AppContext from '../../../../context/AppContext';
import { fetchUserDropdownApi, registerUserApi } from '../../../../api/CompteApi';
import ConfirmInscriptionModal from '../../modals/compte/confirmInscriptionModal/ConfirmInscriptionModal';
import PasswordInput from '../../../molecules/formComponents/passwordInput/PasswordInput';

function InscriptionForm() {
  const {
    register,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [usersTypes, setUsersTypes] = useState([]);
  const [formError, setformError] = useState(<></>);
  const { createNotification, setModalVisible, setModalContent } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    fillDropdown();
  }, []);

  async function onSubmit(values) {
    try {
      const res = await registerUserApi({
        user_type_id: 1,
        email: values.email,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        street: values.street,
        city: values.city,
        zipcode: values.zipcode,
        phone: values.phone,
        company_name: values.company_name || null,
      });
      localStorage.setItem('temporary_uuid', res.data.uuid);
      showConfirmInscriptionModal(values.email);
    } catch (error) {
      let notificationMessage = <></>;

      switch (error.response.status) {
        case 400:
          notificationMessage = <>Tous les champs requis ne sont pas renseignés.</>;
          break;
        case 422:
          switch (error.response.data.infos.error_code) {
            case '01':
              notificationMessage = <>Votre email est incorrect.</>;
              break;
            case '01':
              notificationMessage = <>Votre numéro de téléphone est incorrect.</>;
              break;
            case '03':
              notificationMessage = (
                <>
                  Cet email est déjà utilisé.&nbsp;
                  <a href='/connexion' className='outfit-bold text-underline cursor-pointer'>
                    Me connecter
                  </a>
                </>
              );
              break;
            default:
              notificationMessage = (
                <>Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.</>
              );
          }
          break;
        case 500:
        default:
          notificationMessage = <>Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.</>;
      }

      setformError(notificationMessage);
      createNotification(notificationMessage, 'var(--red)', 'var(--dark-blue');
    }
  }

  function showConfirmInscriptionModal() {
    setModalContent({
      title: 'Confirmer votre inscription',
      content: (
        <ConfirmInscriptionModal email={getValues('email')} password={getValues('password')} navigate={navigate} />
      ),
    });
    setModalVisible(true);
  }

  async function fillDropdown() {
    try {
      const types = (await fetchUserDropdownApi('type')).data;
      setUsersTypes(types);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de données importantes.&nbsp;
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        'var(--red)',
        'var(--dark-blue)'
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className='borderless-fieldset full-page-form my-md'>
        <div className='row-1000'>
          <Input
            register={register}
            error={errors?.last_name && errors.last_name.message}
            name='last_name'
            label='Nom&nbsp;*'
          />
          <Input
            register={register}
            error={errors?.first_name && errors.first_name.message}
            name='first_name'
            label='Prénom&nbsp;*'
          />
        </div>
        <Input
          register={register}
          validation={''}
          error={errors?.company_name && errors.company_name.message}
          name='company_name'
          label='Raison sociale'
        />
        <Input
          register={register}
          error={errors?.street && errors.street.message}
          name='street'
          label='Numéro et voie&nbsp;*'
        />
        <div className='row-1000'>
          <Input
            register={register}
            error={errors?.zipcode && errors.zipcode.message}
            name='zipcode'
            label='Code postal&nbsp;*'
          />
          <Input register={register} error={errors?.city && errors.city.message} name='city' label='Ville&nbsp;*' />
        </div>
        <Select
          register={register}
          error={errors?.id_type_user && errors.id_type_user.message}
          name='id_type_user'
          label='Je suis&nbsp;*'
          children={usersTypes.map(type => (
            <option key={type.id} id={type.id}>
              {type.description}
            </option>
          ))}
        />
        <div className='row-1000'>
          <Input
            register={register}
            validation={emailValidation}
            error={errors?.email && errors.email.message}
            name='email'
            label='Adresse mail&nbsp;*'
          />
          <Input
            register={register}
            validation={phoneValidation}
            error={errors?.phone && errors.phone.message}
            name='phone'
            label='Téléphone&nbsp;*'
          />
        </div>
        <div className='row-1000'>
          <PasswordInput
            register={register}
            showTooltip
            error={errors?.password && errors.password.message}
            name='password'
            label='Mot de passe&nbsp;*'
            watch={watch}
          />
          <PasswordInput
            register={register}
            isPasswordConfirm
            error={errors?.password_confirm && errors.password_confirm.message}
            name='password_confirm'
            label='Confirmez votre mot de passe&nbsp;*'
            watch={watch}
          />
        </div>
        <Checkbox
          validation={defaultValidation}
          register={register}
          error={errors?.personal_data && errors.personal_data.message}
          name='personal_data'
          label={
            <p>
              En soumettant ce formulaire, j'accepte que mes données personnelles saisies soient utilisées pour les
              besoins de la création de mon compte client et pour l’exécution des services associés.&nbsp;*
            </p>
          }
        />
        <Checkbox
          error={errors?.cgu_cgv && errors.cgu_cgv.message}
          register={register}
          validation={defaultValidation}
          name='cgu_cgv'
          label={
            <p>
              En cochant cette case, je déclare avoir pris connaissance et accepter sans réserve les{' '}
              <Link to='/cgv' className='span-inherit gold-link'>
                Conditions Générales de Vente
              </Link>{' '}
              de la société GALAXICO, ainsi que ses{' '}
              <Link to='/cgu' className='span-inherit gold-link'>
                Conditions Générales d’Utilisation
              </Link>{' '}
              et sa{' '}
              <Link to='/politique-confidentialite' className='span-inherit gold-link'>
                Politique de confidentialité
              </Link>
              .&nbsp;*
            </p>
          }
        />
        <div className='form-error-container'>{formError}</div>
        <div className='form-legend'>* Obligatoire</div>
        <div className='centered'>
          <FilledButton type='submit' padding='10px 25px'>
            S'inscrire
          </FilledButton>
        </div>
      </fieldset>
    </form>
  );
}

export default InscriptionForm;
