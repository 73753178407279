import { useNavigate } from 'react-router-dom';
import ConnexionForm from '../../../components/forms/compte/connexionForm/ConnexionForm';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import AppContext from '../../../context/AppContext';
import './Connexion.css';
import React, { useContext, useEffect } from 'react';

function Connexion() {
  const { getUserId } = useContext(AppContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(getUserId() && !localStorage.getItem('account_not_activated')) navigate('/mon-compte')
  }, [getUserId()])

  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            { name: 'Accueil', to: '/' },
            { name: 'Connexion', to: '/connexion' },
          ]}
        />
        <Banner title='Connexion' subtitle='Bienvenue, connectez-vous afin de profiter de nos offres' />
        <div className='connexion-page-container'>
          <ConnexionForm />
          <div>
            <h2 className='h2-uppercase'>Pas encore de compte ? </h2>
            <p>
              L'inscription à notre site vous permet d'accéder à toutes ses fonctionnalités et à l'historique de vos
              commandes. Nous allons créer un nouveau compte pour vous en un instant, il vous suffit de renseigner
              quelques informations nécessaires.
            </p>
            <div className='centered mt-md'>
              <FilledButton padding='10px 25px' to='/inscription'>
                Créer mon compte
              </FilledButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Connexion;
